/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from 'react'
import { KTSVG, isNotEmpty } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useLearnVocabulary } from '../../../../app/modules/apps/learn/vocabulary/core/LearnVocabularyProvider'
import { AddExampleLogInput } from '../../../../app/modules/apps/learn/vocabulary/core/_models'

type Props = {
    example_id: number;
    sentence_hint_id: number;
    word_hint_id: number;
    reloadCards: (msg: string) => void;
}

const InformProblem: FC<Props> = ({ example_id, sentence_hint_id, word_hint_id, reloadCards }) => {
    const intl = useIntl()
    const { addExampleLog } = useLearnVocabulary();
    const closeButton = useRef<HTMLButtonElement>(null);

    const [formData] = useState<AddExampleLogInput>({
        example_id,
        sentence_hint_id,
        word_hint_id,
        problems_id: [],
        comment: ""
    });

    const formik = useFormik({
        initialValues: formData,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.problems_id)) {
                    await addExampleLog(values);
                    closeButton.current?.click();
                    reloadCards(intl.formatMessage({ id: 'LEARN.FEEDBACK' }))
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(false)
            }
        },
    });

    const problems = [
        {
            name: "quality",
            value: "1",
            text: 'Esta frase de ejemplo es de mala calidad.'
        },
        {
            name: "translation",
            value: "2",
            text: 'Hay un problema con la traducción de la frase.'
        },
        {
            name: "grammar",
            value: "3",
            text: 'La información gramatical es incorrecta'
        },
        {
            name: "word",
            value: "4",
            text: 'Hay un problema con la traducción de la palabra.'
        },
        {
            name: "syn",
            value: "5",
            text: 'Mi sinónimo no ha sido aceptado'
        },
        {
            name: "sound",
            value: "6",
            text: 'El sonido no funciona'
        },
    ]

    return (
        <div className='modal fade pt-20' id='kt_modal_inform_problem' aria-hidden='true'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <form className='form mb-5 mx-6' onSubmit={formik.handleSubmit} noValidate>
                        <div className='modal-header pb-0 border-0 justify-content-between d-flex mb-10'>
                            <h1 className=''>Informar de un problema</h1>
                            {/* <h1 className='mb-5 mx-2'>{intl.formatMessage({ id: "LEARN.LEVEL.TITLE" })}</h1> */}
                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        {
                            problems.map((problem, i) => {
                                return (
                                    <div className='d-flex align-items-start mb-8'
                                        key={i}>
                                        <div className='form-check form-check-custom form-check-solid mx-5'>
                                            <input
                                                name="problems_id"
                                                className='form-check-input'
                                                type='checkbox'
                                                value={problem.value}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        {/* begin::Description */}
                                        <div className='flex-grow-1'>
                                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                                {problem.text}
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <textarea
                            style={{ resize: 'none' }}
                            className='form-control form-control-solid mb-8'
                            rows={3}
                            placeholder='Por favor incluye una descripción (opcional)'
                            name="comment"
                            onChange={formik.handleChange}
                        ></textarea>

                        <div className='text-end'>
                            <button
                                ref={closeButton}
                                type='reset'
                                className='btn btn-light me-3'
                                data-bs-dismiss='modal'
                            >
                                {intl.formatMessage({ id: "DASHBOARD.GOALCARD.DISCARD" })}
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Enviar comentarios</span>
                                {(formik.isSubmitting) &&
                                    (
                                        <span className='indicator-progress'>
                                            {intl.formatMessage({ id: "WAIT" })}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { InformProblem }
