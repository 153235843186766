import axios, {AxiosResponse} from 'axios'
import {AddExampleLogInput, AddFavoriteInput, LearnDataResponse, setUserProgressInput } from './_models';
import qs from 'qs';


const API_URL = process.env.REACT_APP_THEME_API_URL
const LEARN_URL = `${API_URL}/learn`

const getLearnVocabularyData = (): Promise<LearnDataResponse> => {

  return axios
    .get(`${LEARN_URL}/learn_data`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response: AxiosResponse<LearnDataResponse>) => response.data)
    // .then((response: Response<LearnDataResponse>) => response.data)
}


const addFavoriteWord = (input: AddFavoriteInput): Promise<Boolean> => {

  const data = qs.stringify(input);
  return axios
    .post(`${LEARN_URL}/favorite`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: any) => response.data)
}

const addExampleLog = (input: AddExampleLogInput): Promise<Boolean> => {
  // we will directly send input as json. Axios handles json data by default.
  return axios
    .post(`${LEARN_URL}/examplelog`, input)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: any) => response.data)
}


const setUserProgress = (input: setUserProgressInput): Promise<Boolean> => {

  const data = qs.stringify(input);
  return axios
    .post(`${LEARN_URL}/progress`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: any) => response.data)
}

export {getLearnVocabularyData, addFavoriteWord, setUserProgress, addExampleLog}



// import {Response} from '../../../../../../_metronic/helpers'
// import { LearnDataModel, LearnDataResponse } from './_models';
// const getLearnVocabularyData = (): Promise<LearnDataResponse> => {

//      const fakeLearnData: LearnDataModel = {
//         todayProgress: 1,
//         learningRate: 50,
//         senses: [
//           {
//             sense_id: "1",
//             word_id: "1",
// word_audio_url: "https://d1qx7pbj0dvboc.cloudfront.net/run.mp3",

//             deck_id: "1",
//             learning_status: 2,
//             is_favorite: false,
//             comments: [],
//             hints: [
//               {
//                 hint_id: "1",
//                 hint: "correr",
//                 comments: [],
//                 tags: [],
//                 images: [],
//               },
//               {
//                 hint_id: "2",
//                 hint: "ejecutar",
//                 comments: [],
//                 tags: [],
//                 images: [],
//               },
//             ],
//             contexts: [
//               {
//                 uuid: "1",
//                 lemma: "run",
//                 form : "run",
//                 context: "I like to run in the morning",
//                 audio_url: "https://d1qx7pbj0dvboc.cloudfront.net/run.mp3",
//                 hints: [
//                   {
//                     hint_id: "1",
//                     hint: "Me gusta correr por las mañanas",
//                     comments: [],
//                     tags: [],
//                     images: [],
//                   },
//                   {
//                     hint_id: "2",
//                     hint: "Me gusta hacer ejercicio por las mañanas",
//                     comments: [],
//                     tags: [],
//                     images: [],
//                   },
//                 ],
//               },
//             ],
//             parsed_grammar: {
//               icon: null,
//               pos: "noun",
//               tense: "singular",
//               fragments: [{ full: "verbo, presente", short: "verbo presente" }],
//               explanation: "Acción que se realiza en el presente",
//             },
//           },
//           {
//             word_id: "2",
//             sense_id: "2",

// word_audio_url: "https://d1qx7pbj0dvboc.cloudfront.net/walk.mp3",
//             deck_id: "1",
//             learning_status: 3,
//             is_favorite: true,
//             comments: [],
//             hints: [
//               {
//                 hint_id: "1",
//                 hint: "caminar",
//                 comments: [],
//                 tags: [],
//                 images: [],
//               },
//               {
//                 hint_id: "2",
//                 hint: "andar",
//                 comments: [],
//                 tags: [],
//                 images: [],
//               },
//             ],
//             contexts: [
//               {
//             lemma: "walk",
//             form: "walk",
//                 uuid: "2",
//                 context: "Let's take a walk in the park",
//                 audio_url: "https://d1qx7pbj0dvboc.cloudfront.net/walk.mp3",
//                 hints: [
//                   {
//                     hint_id: "1",
//                     hint: "Vamos a caminar en el parque",
//                     comments: [],
//                     tags: [],
//                     images: [],
//                   },
//                   {
//                     hint_id: "2",
//                     hint: "Demos un paseo por el parque",
//                     comments: [],
//                     tags: [],
//                     images: [],
//                   },
//                 ],
//               },
//             ],
//             parsed_grammar: {
//               icon: null,
//               pos: "verb",
//               tense: "present",
//               fragments: [{ full: "verbo, presente", short: "verbo presente" }],
//               explanation: "Acción que se realiza en el presente",
//             },
//           }
//         ],
//       };
    
  

//   const fakeResponse: Response<LearnDataModel> = {
//     data: fakeLearnData,
//     // totalCount: fakeDecks.length
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(fakeResponse)
//     }, 2000)
//   })
//   }

