/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { useLang, setLanguage } from '../../../i18n/Algernoun18n'
import { useAuth } from '../../../../app/modules/auth'
import { useIntl } from 'react-intl'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-kingdom.svg'),
  },
  {
    lang: 'zh',
    name: '中文',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  {
    lang: 'es',
    name: 'Español',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'ca',
    name: 'Català',
    flag: toAbsoluteUrl('/media/flags/catalonia.svg'),
  },
  {
    lang: 'ar-MA',
    name: 'الدارجة',
    flag: toAbsoluteUrl('/media/flags/morocco.svg'),
  },
  {
    lang: 'ja',
    name: '日本語',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    lang: 'de',
    name: 'Deutsch',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'Français',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
];


type Props = {
  languageMenuPlacement?: 'lert-start' | 'right-end'
}

const Languages: FC<Props> = ({ languageMenuPlacement = 'left-start'}) => {
  const lang = useLang()
  const intl = useIntl()

  const { updateUserLanguage, currentUser } = useAuth()


  const currentLanguage = languages.find((x) => x.lang === (currentUser?.language ?? lang)) ?? languages[0]

  if (!lang){
    setLanguage(currentLanguage.lang)
  }

  function updateUserCurrentLanguage(lang: string) {
    if (currentUser){
      updateUserLanguage(lang).then(() => {
        setLanguage(lang)
      })
    }else{
      setLanguage(lang)
    }
  }
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement={languageMenuPlacement}
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({ id: 'MENU.LANGUAGE' })}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='language'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              updateUserCurrentLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Languages }
