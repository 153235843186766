/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC} from 'react'

type Props = {
    done?: number
    out_of?: number
}

const UtilityProgress: FC<Props> = ({
    done,
    out_of
}) => {
    
    let width = done && out_of ? (done/out_of) * 100 : 0
    return (

        <div className='d-flex my-auto w-100' style={{maxWidth: "250px"}}>
            <span className='mx-2 fs-4'>{done}</span>
            <div className='progress w-100 my-auto'>
                <div
                    className={`progress-bar bg-success`}
                    role='progressbar'
                    style={{ width: width + "%"}}
                ></div>
            </div>
            <span className='mx-2 fs-4'>{out_of}</span>
        </div>
    )
}

export { UtilityProgress }
