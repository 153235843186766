import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DecksListHeader} from './components/header/DecksListHeader'
import {DecksTable} from './table/DecksTable'
import {DeckEditModal} from './deck-edit-modal/DeckEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

const DecksList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DecksListHeader />
        <DecksTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DeckEditModal />}
    </>
  )
}

const DecksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DecksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DecksListWrapper}
