import { FC, useState } from "react";
import { toAbsoluteUrl } from "../../helpers";
import { updateUserLanguage } from "../../../app/modules/auth/core/_requests";
import { setLanguage } from "../../i18n/Algernoun18n";


const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-kingdom.svg'),
        title: 'Select your native or preferred language.'
    },
    {
        lang: 'zh',
        name: '中文',
        flag: toAbsoluteUrl('/media/flags/china.svg'),
        title: '选择您的母语或首选语言。'
    },
    {
        lang: 'es',
        name: 'Español',
        flag: toAbsoluteUrl('/media/flags/spain.svg'),
        title: 'Seleccione su idioma nativo o de preferencia.'
    },
    {
        lang: 'ca',
        name: 'Català',
        flag: toAbsoluteUrl('/media/flags/catalonia.svg'),
        title: 'Seleccioneu el vostre idioma natiu o preferit.'
    },
    {
        lang: 'ar-MA',
        name: 'الدارجة',
        flag: toAbsoluteUrl('/media/flags/morocco.svg'),
        title: 'اختر لغتك الأم أو اللغة المفضلة.'
    },
    {
        lang: 'ja',
        name: '日本語',
        flag: toAbsoluteUrl('/media/flags/japan.svg'),
        title: '母国語または希望の言語を選択してください。'
    },
    {
        lang: 'de',
        name: 'Deutsch',
        flag: toAbsoluteUrl('/media/flags/germany.svg'),
        title: 'Wählen Sie Ihre Muttersprache oder bevorzugte Sprache aus.'
    },
    {
        lang: 'fr',
        name: 'Français',
        flag: toAbsoluteUrl('/media/flags/france.svg'),
        title: 'Sélectionnez votre langue maternelle ou préférée.'
    },
];


const AlgernounInitialForm: FC<{ visible?: boolean }> = ({ visible = true }) => {

    // const { mode } = useThemeMode()
 
    const [title, setTitle] = useState("Select your native or preferred language.")

    function updateFormLanguage(text: string| undefined) {
        setTitle(text ?? "")
    }

    function updateUserCurrentLanguage(lang: string) {
        updateUserLanguage(lang).then(() => {
            setLanguage(lang)
        })
    }

    return (
        <>
            {/* <a href="/" className="m-5">
                {mode === 'light' && (
                    <img
                        alt='Logo'
                        className='theme-light-show h-30px'
                        src={toAbsoluteUrl('/media/logos/algernoun.svg')}
                    />
                )}

                {mode === 'dark' && (
                    <img
                        alt='Logo'
                        className='theme-dark-show h-30px'
                        src={toAbsoluteUrl('/media/logos/algernoun-dark.svg')}
                    />
                )}
            </a> */}
            <div className="container">

                <div className="row mx-0 mt-15 mb-15">

                    {!visible && <div className='container m-auto text-center'><span className='spinner-border align-middle text-gray-400' /></div>}
                    <div className="text-center fs-1 mb-10">
                        {title}
                    </div>
                    {languages.map((l) => (
                        <div
                            className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 my-4'
                            key={l.lang}
                            onMouseOver={()=>{
                                updateFormLanguage(l.title)
                            }}
                            onClick={() => {
                                updateUserCurrentLanguage(l.lang)
                            }}
                        >
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top-left'
                                data-bs-trigger='hover'
                                title={l.name}
                            >

                                <div
                                    className={'card text-center p-2'}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img className='rounded-1 p-5' src={l.flag} alt='Flag' />

                                    <span className="p-2"> {l.name} </span>
                                    
                                </div>
                                {/* <div className="text-dark text-center fs-3">{l.name}</div> */}
                            </div>
                        </div>
                    ))}
                    {/* {tokens.filter((token) => !token.isStopWord).map((token) => (
                    <div key={token.lemma} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 my-4">
                        <TokenInfo className={''} token={token} />
                    </div>
                ))} */}
                </div>
            </div>
        </>
    )

}

export { AlgernounInitialForm }