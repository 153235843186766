import {FC} from 'react'

type Props = {
  creation?: string
}

const DeckCreationCell: FC<Props> = ({creation}) => (
  <div className='badge badge-light fw-bolder'>{creation}</div>
)

export {DeckCreationCell}
