/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { UtilityActions } from './actions/UtilityActions'
import { UtilityProgress } from './indicators/UtilityProgress'
import Dictaphone from '../components/Dictaphone';

interface UtilityBeltProps {
  className?: string;
  card_language: string,
  todayProgress?: number;
  learningRate?: number;
  inputValue: string;
  stopRecording: boolean;
  disabled:boolean;
  checkAnswer: () => void;
  setInputValue: (text: string) => void;
  setRecording: (state:boolean) => void;
}

const UtilityBelt: FC<UtilityBeltProps> = ({
  className,
  card_language,
  todayProgress,
  learningRate,
  inputValue,
  stopRecording,
  disabled,
  checkAnswer,
  setInputValue,
  setRecording
}) => {
  return (
    <div className={`card ${className}`}>

      {/* begin::Body */}
      <div className='card-body d-flex justify-content-between w-100 p-2'>
        <div className='my-auto d-flex w-50'>
          <Dictaphone language={card_language} setInputValue={setInputValue} stopRecording={stopRecording} setRecording={setRecording} disabled={disabled}/>
          <UtilityProgress done={todayProgress} out_of={learningRate} />
        </div>
        <UtilityActions inputValue={inputValue}
          checkAnswer={checkAnswer}  disabled={disabled}/>
      </div>
      {/* end::Body */}
    </div>
  )
};

export { UtilityBelt }
