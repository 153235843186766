import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {LevelProgress} from '../../modules/apps/learn/vocabulary/utilities/level/LevelProgress'

const Feature1 = () => {
  return (
    <div className='container'>
      <h1 className='fs-2hx text-dark mb-4'>
        Algernoun offers the essential tools for enhancing your learning experience
      </h1>
      <div className='row g-5 g-xl-8 my-5'>
        <div className='col-xl-4 col-md-4 col-12'>
          <div className='col-12'>
            <div className='card card-xl-stretch mb-xl-8 btn btn-outline p-0'>
              <div className='card-body d-flex align-items-center pt-3 pb-0 pr-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <span className='fw-bold fs-4 mb-2'>Personalized Decks</span>
                  <span className='fw-semibold text-gray-700 fs-5'>
                    Generate decks tailored to your unique learning goals. Covering languages to
                    Harry Potter spells and more.
                  </span>
                </div>
                {/* <img src="/media/svg/avatars/004-boy-1.svg" alt="" className="align-self-end h-100px" /> */}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='card card-xl-stretch mb-xl-8 btn btn-outline p-0'>
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <span className='fw-bold fs-4 mb-2'>Interactive Exercises</span>
                  <span className='fw-semibold text-gray-700 fs-5'>
                    Engage in interactive fill-the-gap exercises designed to consolidate your
                    knowledge and improve long-term memory retention.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='card card-xl-stretch mb-5 mb-xl-8 btn btn-outline p-0'>
              <div className='card-body d-flex align-items-center pt-1 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <span className='fw-bold fs-4 mb-2'>Spaced Repetition</span>
                  <span className='fw-semibold text-gray-700 fs-5'>
                    Leverage the power of spaced repetition techniques to learn effectively and
                    remember more.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-8 col-md-8 col-12 d-flex align-items-center'>
          <div className='col-12 col-md-8 col-xl-7 m-auto' style={{maxWidth: '650px'}}>
            <div className='d-flex flex-column justify-content-between'>
              <div className='card card-stretch mb-xl-10 mb-lg-10 mb-6 '>
                <div className='card-header py-2 px-5'>
                  <div className='d-flex justify-content-between align-items-between w-100 h-75 my-auto'>
                    <LevelProgress level={0} />
                    <button
                      type='button'
                      aria-label='Options'
                      className='btn btn-sm btn-icon btn-color-secondary btn-active-light-secondary h-75 my-auto rounded-circle'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <span className='svg-icon svg-icon-1 text-gray-600'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen053.svg'
                          className='svg-icon-1 text-gray-600'
                        />
                      </span>
                    </button>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-gray-600 menu-state-bg-light-primary w-200px p-0'
                      data-kt-menu='true'
                    >
                      <div className='menu-item p-0'>
                        <div className='menu-link fs-2 px-3 rounded-top'>
                          <span className='svg-icon text-dark svg-icon svg-icon-1 mx-3'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='mh-50px'
                            >
                              <path
                                d='M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z'
                                stroke='currentColor'
                              />
                            </svg>
                          </span>
                          Agregar a favoritos
                        </div>
                      </div>
                      <div className='menu-item p-0'>
                        <div
                          className='menu-link fs-2 px-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_inform_problem'
                        >
                          <span className='svg-icon text-dark svg-icon svg-icon-1 mx-3'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='mh-50px'
                            >
                              <path
                                opacity='0.3'
                                d='M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z'
                                fill='currentColor'
                              />
                              <path
                                d='M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          Informar un problema
                        </div>
                      </div>
                      <div className='menu-item p-0'>
                        <div className='menu-link fs-2 px-3 rounded-bottom'>
                          <span className='svg-icon text-dark svg-icon svg-icon-1 mx-3'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='mh-50px'
                            >
                              <rect
                                opacity='0.3'
                                x={2}
                                y={2}
                                width={20}
                                height={20}
                                rx={10}
                                fill='currentColor'
                              />
                              <path
                                d='M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          Atajos de teclado
                        </div>
                      </div>
                    </div>
                    <div
                      className='modal fade pt-20'
                      id='kt_modal_inform_problem'
                      aria-hidden='true'
                    >
                      <div className='modal-dialog'>
                        <div className='modal-content'>
                          <form className='form mb-5 mx-6' noValidate>
                            <div className='modal-header pb-0 border-0 justify-content-between d-flex mb-10'>
                              <h1 className=''>Informar de un problema</h1>
                              <div
                                className='btn btn-sm btn-icon btn-active-color-primary'
                                data-bs-dismiss='modal'
                              >
                                <span className='svg-icon svg-icon-1'>
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='mh-50px'
                                  >
                                    <rect
                                      opacity='0.5'
                                      x={6}
                                      y='17.3137'
                                      width={16}
                                      height={2}
                                      rx={1}
                                      transform='rotate(-45 6 17.3137)'
                                      fill='currentColor'
                                    />
                                    <rect
                                      x='7.41422'
                                      y={6}
                                      width={16}
                                      height={2}
                                      rx={1}
                                      transform='rotate(45 7.41422 6)'
                                      fill='currentColor'
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={1}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  Esta frase de ejemplo es de mala calidad.
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={2}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  Hay un problema con la traducción de la frase.
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={3}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  La información gramatical es incorrecta
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={4}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  Hay un problema con la traducción de la palabra.
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={5}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  Mi sinónimo no ha sido aceptado
                                </div>
                              </div>
                            </div>
                            <div className='d-flex align-items-start mb-8'>
                              <div className='form-check form-check-custom form-check-solid mx-5'>
                                <input
                                  name='problems_id'
                                  className='form-check-input'
                                  type='checkbox'
                                  defaultValue={6}
                                />
                              </div>
                              <div className='flex-grow-1'>
                                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                  El sonido no funciona
                                </div>
                              </div>
                            </div>
                            <textarea
                              className='form-control form-control-solid mb-8'
                              rows={3}
                              placeholder='Por favor incluye una descripción (opcional)'
                              name='comment'
                              style={{resize: 'none'}}
                              defaultValue={''}
                            />
                            <div className='text-end'>
                              <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-bs-dismiss='modal'
                              >
                                Descartar
                              </button>
                              <button
                                type='reset'
                                className='btn btn-primary'
                                data-bs-dismiss='modal'
                              >
                                <span className='indicator-label'>Enviar comentarios</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pb-0'>
                  <div className='fill-word pb-5'>
                    <span className='display-6 fw-normal text-primary'>
                      <span>Learn in the </span>
                    </span>
                    <span className='display-6 fw-normal text-primary'>
                      <span className='d-inline-block bg-secondary px-lg-2 px-xl-2 px-sm-0 px-md-0 px-0 rounded-1'>
                        <input
                          className='text-primary bg-transparent border-0'
                          type='text'
                          autoComplete='off'
                          autoCorrect='off'
                          autoCapitalize='off'
                          spellCheck='false'
                          data-word='day'
                          data-lpignore='true'
                          placeholder=''
                          defaultValue=''
                          style={{
                            outline: 'none',
                            boxSizing: 'content-box',
                            maxWidth: '400px',
                            width: '2px',
                            minWidth: 'calc(68px)',
                          }}
                        />
                        <p style={{position: 'absolute', width: 'fit-content', opacity: 0}}>best</p>
                        <span className='hidden-word-container d-none'>
                          <span className='is-letter'>b</span>
                          <span className='is-letter'>e</span>
                          <span className='is-letter'>s</span>
                          <span className='is-letter'>t</span>
                        </span>
                      </span>
                    </span>
                    <span className='display-6 fw-normal text-primary'>
                      <span> way thanks to our method.</span>
                    </span>
                  </div>
                  <audio src='https://d1qx7pbj0dvboc.cloudfront.net/best.mp3' />
                  <audio src='/media/audios/correct.mov' />
                  <button
                    type='button'
                    id='kt_drawer_info_toggle'
                    className='btn btn-secondary py-2 px-3 mt-6 mb-7'
                  >
                    <span className='fs-4 mr-3'> Adjective </span>
                    <span className='svg-icon svg-icon-2 text-gray-40 m-0'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <path
                          d='M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className='card-footer bg-secondary pt-2 pb-0'>
                  <div className='accordion accordion-icon-toggle2' id='kt_accordion_exercise'>
                    <div
                      className='accordion-header py-3 d-flex justify-content-between'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_exercise_item'
                    >
                      <span className='fs-2 fw-bold'>mejor</span>
                      <span className='accordion-icon'>
                        <span className='svg-icon text-dark svg-icon svg-icon-1'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mh-50px'
                          >
                            <path
                              d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div
                      id='kt_accordion_exercise_item'
                      className='collapse show'
                      data-bs-parent='#kt_accordion_exercise'
                    >
                      <p className='fs-3 mt-3 text-gray-800'>
                        Aprende de la mejor manera gracias a nuestro metodo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card card-stretch mb-xl-10 mb-lg-10 mb-6'>
                <div className='card-body d-flex justify-content-between w-100 p-2'>
                  <div className='my-auto d-flex w-50'>
                    <button
                      type='button'
                      aria-label='Use Microphone'
                      className='rounded btn btn-sm btn-icon btn-color-gray600 btn-active-secondary d-flex align-items-center ml-4 p-3'
                    >
                      <i className='fas fa-microphone fs-2' />
                    </button>
                    <div className='d-flex my-auto w-100' style={{maxWidth: '250px'}}>
                      <span className='mx-2 fs-4'>0</span>
                      <div className='progress w-100 my-auto'>
                        <div
                          className='progress-bar bg-success'
                          role='progressbar'
                          style={{width: '0%'}}
                        />
                      </div>
                      <span className='mx-2 fs-4'>20</span>
                    </div>
                  </div>
                  <div className='d-flex'>
                    <Link to='/auth'>
                      <button
                        type='button'
                        className='btn btn-sm btn-secondary d-flex align-items-center fs-5 h-100 w-100'
                        style={{maxWidth: 'fit-content'}}
                      >
                        Aprender palabra
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature1
