/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
// import { KTSVG } from '../../../../../../../_metronic/helpers'
import './TestingLevel.css';


const TestingLevel = () => {

    const intl = useIntl()
    const circleStyle = {
        width: '1em',
        height: '1em',
        opacity: '0.8',
        borderRadius: '50%',
        animation: 'ease pulse 3s infinite',
        marginRight: '0.40em',
        marginLeft: '0.50em'
    };

    return (
        
        <div className="w-100 fs-4 d-flex m-aut pl-4">
            <div style={circleStyle} className="bg-success my-auto mr-5"></div>
            <div className="ml-5 my-auto text-gray-600">{intl.formatMessage({ id: "LEARN.TEST.TITLE" })}</div>
        </div>
    )
}

export { TestingLevel }
