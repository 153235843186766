/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {DecksListWrapper} from '../../../../app/modules/apps/deck-management/decks-list/DecksList'
import {useNavigate, Link} from 'react-router-dom'

const Decks: FC = () => {
  const navigate = useNavigate()
  const [learnClicked, setLearnClicked] = useState(false);
  const closeButton = useRef<HTMLButtonElement>(null);
  
  useEffect(() => {
    const modalElement = document.getElementById('kt_modal_decks')

    const handleModalHide = () => {
      if (!learnClicked) {
        navigate('/');
      }else{
        navigate('/apps/learn/vocabulary');
        setLearnClicked(false);
      }
    }

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHide)
      return () => {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHide)
      }
    }
  }, [navigate, learnClicked])


  const handleLearnClick = () => {
    setLearnClicked(true);
    closeButton.current?.click();
  };

  return (
    <div className='modal fade' id='kt_modal_decks' aria-hidden='true'>
      <div className='modal-dialog'>
        <div className='modal-content p-5' style={{width: 'fit-content'}}>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <button ref={closeButton} className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>

          <div className='modal-body scroll-y pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Lista de mazos</h1>
            </div>
            <DecksListWrapper />
          </div>

          <div className='modal-footer'>
            <Link to={'/apps/learn/vocabulary'} className='btn btn-primary' onClick={handleLearnClick} >
              Learn
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Decks}
