// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
// import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  backGroundColor: string
}

const DashboardAdds: FC<Props> = (
  {
    className,
    backGroundColor,
  }) => {

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{ backgroundColor: backGroundColor }}
    >

      <div className="card-header py-5 border-bottom-0">


      </div>


      <div className="card-body d-flex align-items-end pt-0">

      Esto es publicidad

      </div>

    </div>
  );
}

export { DashboardAdds }
