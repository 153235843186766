import axios, {AxiosResponse} from 'axios'
import qs from 'qs';
import {Response} from '../../../../../../_metronic/helpers'
import {
  TranslationInput,
  TranslationOutput,
  DisambiguationInput,
  WordToken,
} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TRANSLATION_URL = `${API_URL}/data/translate`
const ANALYSIS_URL = `${API_URL}/data/analysis`

const getTranslation = (input: TranslationInput): Promise<TranslationOutput | undefined> => {

  const data = qs.stringify(input);

  return axios
    .post(`${TRANSLATION_URL}`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response: AxiosResponse<Response<TranslationOutput>>) => response.data)
    .then((response: Response<TranslationOutput>) => response.data)
}

const getDisambiguation = (input : DisambiguationInput): Promise<WordToken[] | undefined> => {

  const data = qs.stringify(input);

  return axios
    .post(`${ANALYSIS_URL}`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response: AxiosResponse<Response<WordToken[]>>) => response.data)
    .then((response: Response<WordToken[]>) => response.data)
  }

// const getDisambiguation = (input: DisambiguationInput): Promise<DisambiguationResponse> => {
//   const fakeTokens: WordToken[] = [
//     {
//       lemma: 'Hi',
//       isLearning: false,
//       isStopWord: false,
//       pos: 'interjection',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'used as a friendly greeting or to attract attention',
//         },
//       ],
//     },
//     {
//       lemma: 'I',
//       isLearning: false,
//       isStopWord: true,
//       pos: 'pronoun',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'used to refer to oneself as speaker or writer',
//         },
//       ],
//     },
//     {
//       lemma: 'come',
//       isLearning: false,
//       isStopWord: false,
//       pos: 'verb',
//       tense: 'past',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'move or travel towards or into a place',
//         },
//       ],
//     },
//     {
//       lemma: 'here',
//       isLearning: false,
//       isStopWord: false,
//       pos: 'adverb',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'in, at, or to this place or position',
//         },
//       ],
//     },
//     {
//       lemma: 'to',
//       isLearning: false,
//       isStopWord: true,
//       pos: 'preposition',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'expressing motion in the direction of (a particular location)',
//         },
//       ],
//     },
//     {
//       lemma: 'Barcelona',
//       isLearning: false,
//       isStopWord: false,
//       pos: 'proper noun',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss:
//             'a city in northeastern Spain, on the Mediterranean coast; population 1,608,746 (2008).',
//         },
//       ],
//     },
//     {
//       lemma: 'to',
//       isLearning: false,
//       isStopWord: true,
//       pos: 'preposition',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'expressing motion in the direction of (a particular location)',
//         },
//       ],
//     },
//     {
//       lemma: 'see',
//       isLearning: true,
//       isStopWord: false,
//       pos: 'verb',
//       tense: 'infinitive',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'perceive with the eyes; discern visually',
//         },
//       ],
//     },
//     {
//       lemma: 'the',
//       isLearning: false,
//       isStopWord: true,
//       pos: 'determiner',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss:
//             'denoting one or more people or things already mentioned or assumed to be common knowledge',
//         },
//       ],
//     },
//     {
//       lemma: 'Camp Nou',
//       isLearning: false,
//       isStopWord: false,
//       pos: 'proper noun',
//       tense: '',
//       definitions: [
//         {
//           senseId: '',
//           languageCode: '',
//           disambiguationScore: 0,
//           gloss: 'the stadium of FC Barcelona',
//         },
//       ],
//     },
//   ]

//   const fakeResponse: Response<WordToken[]> = {
//     data: fakeTokens,
//     // totalCount: fakeDecks.length
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(fakeResponse)
//     }, 500)
//   })
// }

// const getDeckById = (id: ID): Promise<Deck | undefined> => {
//   return axios
//     .get(`${USER_URL}/${id}`)
//     .then((response: AxiosResponse<Response<Deck>>) => response.data)
//     .then((response: Response<Deck>) => response.data)
// }

// const createDeck = (user: Deck): Promise<Deck | undefined> => {
//   return axios
//     .put(USER_URL, user)
//     .then((response: AxiosResponse<Response<Deck>>) => response.data)
//     .then((response: Response<Deck>) => response.data)
// }

// const updateDeck = (user: Deck): Promise<Deck | undefined> => {
//   return axios
//     .post(`${USER_URL}/${user.id}`, user)
//     .then((response: AxiosResponse<Response<Deck>>) => response.data)
//     .then((response: Response<Deck>) => response.data)
// }

// const deleteDeck = (userId: ID): Promise<void> => {
//   return axios.delete(`${USER_URL}/${userId}`).then(() => {})
// }

// const deleteSelectedDecks = (userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
//   return axios.all(requests).then(() => {})
// }

export {getTranslation, getDisambiguation}
