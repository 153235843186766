import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Deck, DecksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DECK_URL = `${API_URL}/deck`
// const GET_USERS_URL = `${API_URL}/users/query`

const getDecks = (query: string): Promise<DecksQueryResponse> => {
  return axios
    .get(`${DECK_URL}/search_list?${query}`)
    .then((d: AxiosResponse<DecksQueryResponse>) => d.data)
}

// const getDecks = (query: string): Promise<DecksQueryResponse> => {
//   console.log(query)
//   const fakeDecks: Deck[] = [
//     {
//       id: 1,
//       name: "General English",
//       image: "/media/flags/united-kingdom.svg",
//       language: "English",
//       size: 10,
//       category: "Category 1",
//       autor: "Author 1",
//       creation: "2022-01-01",
//       verified: false,
//       active: false,
//       initials: {
//         label: "D1",
//         state: "success"
//       }
//     },
//     {
//       id: 2,
//       name: "General Spanish",
//       image: "/media/flags/spain.svg",
//       language: "Spanish",
//       size: 5,
//       category: "Category 2",
//       autor: "Author 2",
//       creation: "2022-01-02",
//       verified: true,
//       active: true,
//       initials: {
//         label: "D2",
//         state: "danger"
//       }
//     }
//   ];

//   const fakeResponse: Response<Deck[]> = {
//     data: fakeDecks,
//     // totalCount: fakeDecks.length
//   };

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(fakeResponse);
//     }, 500);
//   });
// };


const getDeckById = (id: ID): Promise<Deck | undefined> => {
  return axios
    .get(`${DECK_URL}/${id}`)
    .then((response: AxiosResponse<Response<Deck>>) => response.data)
    .then((response: Response<Deck>) => response.data)
}

const createDeck = (user: Deck): Promise<Deck | undefined> => {
  return axios
    .put(DECK_URL, user)
    .then((response: AxiosResponse<Response<Deck>>) => response.data)
    .then((response: Response<Deck>) => response.data)
}

const updateDeck = (user: Deck): Promise<Deck | undefined> => {
  return axios
    .post(`${DECK_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Deck>>) => response.data)
    .then((response: Response<Deck>) => response.data)
}


const subscribeDeck = (deckId: ID): Promise<Boolean | undefined> => {
  return axios
    .get(`${DECK_URL}/subscribe/${deckId}`)
    .then((response: AxiosResponse<Response<Boolean>>) => response.data)
    .then((response: Response<Boolean>) => response.data)
}


const deleteSelectedDecks = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${DECK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getDecks, subscribeDeck, deleteSelectedDecks, getDeckById, createDeck, updateDeck}
