/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from 'react'
import { KTSVG, isNotEmpty } from '../../../helpers'
import { useIntl } from 'react-intl'
import './range.css'
import { useFormik } from 'formik'
import { updateUserLearningRate } from '../../../../app/modules/auth/core/_requests'
type Props = {
    range: number
    onChangeHandler: (msg: string) => void
}

const LearningRate: FC<Props> = ({ range, onChangeHandler }) => {
    const intl = useIntl()
    const inputRef = useRef<HTMLInputElement>(null);
    const closeButton = useRef<HTMLButtonElement>(null);
    const [selectedRange, setSelectedRange] = useState(range);

    const handleRangeChange = () => {
        formik.values.range = Number.parseInt(inputRef.current?.value ?? '0')
        setSelectedRange(Number.parseInt(inputRef.current?.value ?? '0'))
    }

    const [userForEdit] = useState<any>({
        range: range
    })

    const formik = useFormik({
        initialValues: userForEdit,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
                if (isNotEmpty(values.range)) {
                    await updateUserLearningRate(values.range)
                    closeButton.current?.click();
                    onChangeHandler(intl.formatMessage({ id: "DASHBOARD.GOALCARD.CHANGED" }))
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <div className='modal fade pt-20' id='kt_modal_learning_rate' aria-hidden='true'>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header pb-0 border-0 justify-content-end'>
                                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>

                            <div className='modal-body  mx-5 mx-xl-18 pt-0 pb-0'>
                                <div className='mb-13'>
                                    <h1 className='mb-5'>{intl.formatMessage({ id: "DASHBOARD.GOALCARD.TITLE" })}</h1>

                                    <div className='text-center my-15'>
                                        <span className='fs-1 text-primary' style={{ fontSize: '3rem !important' }}>{selectedRange} {intl.formatMessage({ id: "DASHBOARD.GOAL.CARDS" })}</span>
                                    </div>
                                    <div className='fs-3'>
                                        <input
                                            {...formik.getFieldProps('range')}
                                            name='range'
                                            ref={inputRef}
                                            type="range"
                                            className="form-range"
                                            id="learningRateRange"
                                            onChange={handleRangeChange}
                                            min={10} max={70} step={10} />
                                        <div className="d-flex justify-content-between mt-2">
                                            <label>10</label>
                                            <label className='fw-bold fs-2'>20</label>
                                            <label className='opacity-0'>30</label>
                                            <label className='opacity-0'>40</label>
                                            <label className='opacity-0'>50</label>
                                            <label className='opacity-0'>60</label>
                                            <label>70</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='separator d-flex flex-center mb-8'></div>

                                <div className='mb-10 fs-2 text-justify'>
                                    {intl.formatMessage({ id: "DASHBOARD.GOALCARD.MESSAGE" })}
                                </div>
                            </div>

                            <div className='modal-footer'>

                                <div className='text-center pt-15'>
                                    <button
                                        ref={closeButton}
                                        type='reset'
                                        className='btn btn-light me-3'
                                        data-bs-dismiss='modal'
                                    >
                                        {intl.formatMessage({ id: "DASHBOARD.GOALCARD.DISCARD" })}
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>{intl.formatMessage({ id: "DASHBOARD.GOALCARD.SUBMIT" })}</span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                                                {intl.formatMessage({ id: "WAIT" })}{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export { LearningRate }
