import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const AUTH_URL = `${API_URL}/auth`

export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_URL}/verify_token`
export const LOGIN_URL = `${AUTH_URL}/login`
export const REGISTER_URL = `${AUTH_URL}/register`
export const LANGUAGE_URL = `${AUTH_URL}/language`
export const LEARNING_RATE_URL = `${AUTH_URL}/rate`
export const REQUEST_PASSWORD_URL = `${AUTH_URL}/forgot_password`
export const GOOGLE_LOGIN_URL = `${AUTH_URL}/google_signin`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function loginWithGoogle(idToken: string) {
  return axios.post(GOOGLE_LOGIN_URL, {id_token: idToken})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function updateUserLanguage(language_code: string) {
  return axios.post(LANGUAGE_URL, {
    language_code,
  })
}


export function updateUserLearningRate(learning_rate: number) {
  return axios.post(LEARNING_RATE_URL, {
    learning_rate,
  })
}


