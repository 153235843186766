import React from 'react';
import Navigation from './Navigation';
import Hero from './Hero';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import { Languages } from '../../../_metronic/partials/layout/header-menus/Languages';


const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="d-flex text-center mb-20">

          <div className="col">
            <a href="https://github.com/hamzabealta/algernoun" target="_blank" rel="noreferrer" className="fs-5 text-dark fw-bold">
              GitHub
            </a>
          </div>

          <div className="col">
            <a href="https://instagram.com/algernoun" target="_blank" rel="noreferrer" className="fs-5 text-dark fw-bold">
              Instagram
            </a>
          </div>
          
          <div className="col">
            <a href="https://twitter.com/algernoun" target="_blank" rel="noreferrer" className="fs-5 text-dark fw-bold">
              Twitter
            </a>
          </div>

          <div className="col">
            <a href="https://www.tiktok.com/@algernoun?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer" className="fs-5 text-dark fw-bold">
              Tiktok
            </a>
          </div>

          <div className="col">
            <a
              href="https://docs.google.com/"
              target="_blank"
              rel="noreferrer"
              className="fs-5 text-dark fw-bold"
            >
              Join Us
            </a>
          </div>
        </div>
      </div>
      
     
      <div className="text-center py-3">
        ©Algernoun Inc. 2023. All rights reserved.
      </div>
    </footer>
  );
};


export {Footer};


// Main component
const LandingPage = () => {

  return (
    <div>
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary  fw-bold py-4 fs-6 w-275px">
      <Languages/>
      </div>
      <Navigation sticky={true}/>
      <Hero />
      <div className="separator my-20"></div>
      <Feature1 />
      <div className="separator mb-15 mt-10"></div>
      <Feature2 />
      <div className="separator my-20"></div>
      <Footer />

    </div>
  );
};

export { LandingPage };
