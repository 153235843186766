
const LoadingExercice = () => (
    <>
        <div className="card card-stretch mb-xl-8 mb-lg-10 mb-6">
            {/* begin::Header */}
            <div className='card-header py-2 px-5'>
                <div className='d-flex justify-content-between align-items-between w-25 h-75 my-auto'>
                    <div className="loading loading-1 h-30px my-auto" />

                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body pb-5'>

                <div className="loading loading-1 h-50px w-50 mb-5" />

                <div className="loading loading-1 h-30px w-25 mt-10 mb-1" />

            </div>
            {/* end::Body */}

            {/* begin::Footer */}
            <div className='card-footer bg-secondary py-4'>
                <div className="loading loading-1 h-20px w-25" />
                <div className="loading loading-1 h-20px w-50 mt-9" />
            </div>
            {/* end::Footer */}
        </div>
    </>
);

const LoadingBelt = () => (
    <>
        <div className="card card-stretch mb-10">

            {/* begin::Body */}
            <div className='card-body d-flex justify-content-between w-100 py-5 px-3'>

                <div className="loading loading-1 h-20px d-flex my-auto w-50" />

                <div className="loading loading-1 h-20px d-flex my-auto w-25 " />

            </div>
            {/* end::Body */}

        </div>
    </>
);
const LoadingScreen = () => {
    return (
        <div className='col-12 col-md-8 col-xl-7 m-auto h-100' style={{ maxWidth: "650px" }}>
            <div className="d-flex flex-column justify-content-between h-100">
                <LoadingExercice />
                <LoadingBelt />
            </div>
        </div>
    );
};

export {LoadingScreen, LoadingBelt}