/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {DropdownWord} from '../utilities/DropdownWord'
import {LevelProgress} from '../utilities/level/LevelProgress'
import {FillWord} from '../task/FillWord'
import {HintWord} from '../task/HintWord'
import {InfoWord} from '../task/InfoWord'
import {UtilityBelt} from '../utilities/UtilityBelt'
import {Context, Sense} from '../core/_models'
import {LoadingBelt} from './LoadingScreen'
import {TestingLevel} from '../utilities/level/TestingLevel'
import {InformProblem} from '../../../../../../_metronic/partials/modals/learning/InformProblem'

type Props = {
  className: string
  sense: Sense | undefined
  todayProgress?: number
  learningRate?: number
  isLoading?: boolean
  next: (deck_id: number, word_id: string, correct: boolean) => Promise<void>
  reloadCards: (msg: string) => void
}
const ExerciseWidget1: React.FC<Props> = ({
  className,
  sense = {
    deck_id: 0,
    example_id: 0,
    sense_id: '0',
    word_id: '0',
    word_language: 'en',
    word_audio_url: '',
    learning_status: 0,
    is_favorite: false,
    is_test: false,
    lemma: '',
    comments: [],
    hints: [],
    contexts: [],
    parsed_grammar: {},
  },
  todayProgress = 0,
  learningRate = 0,
  isLoading = true,
  next,
  reloadCards,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState(false)
  const [correct, setCorrect] = useState(true)
  const [freze, setFreze] = useState(false)
  const [audioError, setAudioError] = useState(false)
  const [learningStatus, setLearningStatus] = useState(sense.learning_status)
  const [placeholder, setPlaceholder] = useState('')
  const randomIndex = useRef(Math.floor(Math.random() * sense?.contexts.length))
  const context: Context = sense?.contexts[randomIndex.current]
  const audioRef = useRef<HTMLAudioElement>(null)
  const audioRef2 = useRef<HTMLAudioElement>(null)
  const [stopRecording, setStopRecording] = useState(false)
  const [recording, setRecording] = useState(false)

  useEffect(() => {
    setLearningStatus(sense.learning_status)
  }, [sense])

  const reset = useCallback(() => {
    setStopRecording(false)
    setInputValue('')
    setCorrect(true)
    setCorrectAnswer(false)
    setAudioError(false)
    setPlaceholder('')
  }, [])

  const handleAudioEnd = useCallback(() => {
    if (correctAnswer) {
      setFreze(true)
      next(sense.deck_id, sense.word_id, correct)
        .then(() => {
          reset()
        })
        .catch((error) => {
          reloadCards(error)
        })
        .finally(()=>{
          setFreze(false)
        })
      
    } else {
      setPlaceholder('')
    }
  }, [correctAnswer, next, correct, sense, reset, reloadCards])

  const checkAnswer = () => {
    setStopRecording(true)
    if (inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() === context.form.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) {
      // Correct answer
      setCorrectAnswer(true)
      if (correct) {
        setLearningStatus(4)
      }
      if (audioError) {
        audioRef2?.current?.play()
      }
    } else {
      // Incorrect answer
      setInputValue('')
      setCorrectAnswer(false)
      setCorrect(false)
    }
    if (!audioError) {
      audioRef?.current?.play()
    }
    setPlaceholder(context.form)
  }

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }, [])

  const handleAudioError = () => {
    setAudioError(true)
  }

  useEffect(() => {
    const currentAudioRef = audioError ? audioRef2.current : audioRef.current // Copy the ref value to a local variable
    currentAudioRef?.addEventListener('ended', handleAudioEnd)
    // Use the local variable in the cleanup function
    return () => {
      currentAudioRef?.removeEventListener('ended', handleAudioEnd)
    }
  }, [audioError, handleAudioEnd])

  const findSentenceWithHints = (hints: any[], sentences: any) => {
    const uniqueHints = Array.from(new Set(hints))

    const hintsWithSpaces = uniqueHints.join(' ')
    const hintsWithoutSpaces = uniqueHints.join('')
    const hintsWithCommas = uniqueHints.join(', ')

    // Check if any sentence contains all hints sequentially
    for (let sentence of sentences) {
      const lowerCaseSentence = sentence.hint.toLowerCase()
      const indexWithSpaces = lowerCaseSentence.indexOf(hintsWithSpaces.toLowerCase())
      const indexWithoutSpaces = lowerCaseSentence.indexOf(hintsWithoutSpaces.toLowerCase())

      if (indexWithSpaces !== -1) {
        return {
          joinedHints: sentence.hint.slice(
            indexWithSpaces,
            indexWithSpaces + hintsWithSpaces.length
          ),
          matchingSentence: sentence.hint,
        }
      } else if (indexWithoutSpaces !== -1) {
        return {
          joinedHints: sentence.hint.slice(
            indexWithoutSpaces,
            indexWithoutSpaces + hintsWithoutSpaces.length
          ),
          matchingSentence: sentence.hint,
        }
      }
    }

    // Otherwise, check if any sentence contains at least one of the hints
    for (let sentence of sentences) {
      const lowerCaseSentence = sentence.hint.toLowerCase()
      if (hints.some((hint) => lowerCaseSentence.includes(hint.toLowerCase()))) {
        return {joinedHints: hintsWithCommas, matchingSentence: sentence.hint}
      }
    }

    return null
  }

  const hints = sense.hints.map((hintObj) => hintObj.hint)

  return (
    <>
      <div className={`card ${className} `}>
        {/* begin::Header */}
        <div className='card-header py-2 px-5'>
          <div className='d-flex justify-content-between align-items-between w-100 h-75 my-auto'>
            {/* begin::Level */}
            {isLoading || !sense ? (
              <div className='loading loading-1 h-30px w-25 my-auto' />
            ) : sense.is_test ? (
              <TestingLevel />
            ) : (
              <LevelProgress level={learningStatus ?? 0} />
            )}

            {/* end::Level */}

            {/* begin::Menu */}
            <button
              type='button'
              aria-label='Options'
              className='btn btn-sm btn-icon btn-color-secondary btn-active-light-secondary h-75 my-auto rounded-circle'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              disabled={isLoading || !sense}
            >
              {!isLoading && (
                <KTSVG
                  path='/media/icons/duotune/general/gen053.svg'
                  className='svg-icon-1 text-gray-600'
                />
              )}
            </button>
            {/* passar el sense id para poder darle a fav en el dropdown */}
            <DropdownWord
              word_id={isLoading || !sense ? '' : sense.word_id}
              is_favorite={isLoading || !sense ? false : sense.is_favorite ?? false}
            />
            {/* end::Menu */}
            {!isLoading && (
              <InformProblem
                example_id={sense.example_id ?? 0}
                word_hint_id={sense.hints[0]?.hint_id ?? 0}
                sentence_hint_id={context.hints[0]?.hint_id ?? 0}
                reloadCards={reloadCards}
              />
            )}
          </div>
        </div>
        {/* end::Header */}

        {isLoading || !sense ? (
          <>
            <div className='card-body pb-5'>
              <div className='loading loading-1 h-50px w-50 mb-5' />

              <div className='loading loading-1 h-30px w-25 mt-10 mb-1' />
            </div>

            <div className='card-footer bg-secondary py-4'>
              <div className='loading loading-1 h-20px w-25' />
              <div className='loading loading-1 h-20px w-50 mt-9' />
            </div>
          </>
        ) : (
          <>
            {/* begin::Body */}
            <div className='card-body pb-0'>
              <FillWord
                word={context?.form}
                context={context?.context}
                correctAnswer={correctAnswer} //boolean
                inputValue={inputValue} //string
                placeholder={placeholder}
                handleInputChange={handleInputChange}
                recording={recording}
                checkAnswer={checkAnswer}
                disabled={freze}
              />
              <audio ref={audioRef} src={context?.form_audio_url ?? '/media/audios/correct.mov'} onError={handleAudioError} />
              <audio ref={audioRef2} src={'/media/audios/correct.mov'} />

              <InfoWord parsed_grammar={sense.parsed_grammar} />
            </div>
            {/* end::Body */}

            {/* begin::Footer */}
            <div className='card-footer bg-secondary pt-2 pb-0'>
              <HintWord
                title={findSentenceWithHints(hints, context?.hints)?.joinedHints}
                description={findSentenceWithHints(hints, context?.hints)?.matchingSentence}
              />
            </div>
            {/* end::Footer */}
          </>
        )}
      </div>

      {isLoading ? (
        <LoadingBelt />
      ) : (
        <UtilityBelt
          className={className}
          card_language={sense.word_language}
          todayProgress={todayProgress}
          learningRate={learningRate}
          inputValue={inputValue}
          stopRecording={stopRecording}
          setRecording={setRecording}
          checkAnswer={checkAnswer}
          setInputValue={setInputValue}
          disabled={freze}
        />
      )}
    </>
  )
}

export {ExerciseWidget1}
