/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl';

type Props = {
    word?: string,
    inputValue: string,
    checkAnswer: () => void;
    disabled:boolean;
}

const UtilityActions: FC<Props> = ({
    word,
    inputValue,
    checkAnswer,
    disabled
}) => {
    const intl = useIntl()
    const introText = intl.formatMessage({ id: "LEARN.BELT.ENTER" })
    const learnText = intl.formatMessage({ id: "LEARN.BELT.LEARN" })
    return (

        <div className='d-flex'>
            <button
                type='button'
                className='btn btn-sm btn-secondary d-flex align-items-center fs-5 h-100 w-100' style={{ maxWidth: 'fit-content' }} onClick={checkAnswer} disabled={disabled}
            >
                {inputValue ? introText : learnText}
            </button>

        </div>
    )
}

export { UtilityActions }
