/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { InfoContext } from '../core/InfoProvider';

type Props = {
  parsed_grammar?: any,
}

const InfoWord: FC<Props> = ({
  parsed_grammar,
}) => {

  const { setInfoTitle, setInfoDescription } = useContext(InfoContext);

  useEffect(() => {
    if (parsed_grammar) {
      setInfoTitle("" + parsed_grammar.pos + " " + parsed_grammar.tense);
      setInfoDescription(parsed_grammar.explanation);
    }
  }, [parsed_grammar,setInfoTitle, setInfoDescription]);

  return (
    <>
      {(parsed_grammar?.pos || parsed_grammar?.tense) &&
        <button
          type='button'
          id='kt_drawer_info_toggle'
          className='btn btn-secondary py-2 px-3 mt-6 mb-7'
        >
          <span className='fs-4 mr-3'> {parsed_grammar.pos} {parsed_grammar.tense} </span>

          <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className="svg-icon-2 text-gray-40 m-0" />

        </button>
      }
    </>
  )
}

export { InfoWord }
