import {Column} from 'react-table'
import {DeckInfoCell} from './DeckInfoCell'
import {DeckLanguageCell} from './DeckLanguageCell'
import {DeckCreationCell} from './DeckCreationCell'
// import {DeckVerificationCell} from './DeckVerificationCell'
// import {DeckActionsCell} from './DeckActionsCell'
// import {DeckSelectionCell} from './DeckSelectionCell'
import {DeckCustomHeader} from './DeckCustomHeader'
// import {DeckSelectionHeader} from './DeckSelectionHeader'

import {Deck} from '../../core/_models'
import { DeckSubscribeCell } from './DeckSubscribeCell'
import { useIntl } from 'react-intl';

const decksColumns: ReadonlyArray<Column<Deck>> = [
  // {
  //   Header: (props) => <DeckSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <DeckSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <DeckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "DECKS.TABLE.COLUMNS.NAME" })} className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <DeckInfoCell deck={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <DeckCustomHeader tableProps={props} title='Category' className='min-w-125px' />,
  //   accessor: 'category',
  // },
  {
    Header: (props) => (
      <DeckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "DECKS.TABLE.COLUMNS.LANGUAGES" })} className='min-w-125px' />
    ),
    id: 'language',
    Cell: ({...props}) => <DeckLanguageCell language={props.data[props.row.index].language} />,
  },
  // {
  //   Header: (props) => (
  //     <DeckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "DECKS.TABLE.COLUMNS.STATUS" })} className='min-w-125px' />
  //   ),
  //   id: 'verified',
  //   Cell: ({...props}) => <DeckVerificationCell verified={props.data[props.row.index].verified} />,
  // },
  {
    Header: (props) => (
      <DeckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "DECKS.TABLE.COLUMNS.CREATION" })} className='min-w-125px' />
    ),
    id: 'creation',
    Cell: ({...props}) => <DeckCreationCell creation={props.data[props.row.index].creation} />,

  },
  {
    Header: (props) => (
      <DeckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "DECKS.TABLE.COLUMNS.ACTIONS" })} className='text-end min-w-100px' />
    ),
    id: 'actions',
    // Cell: ({...props}) => <DeckActionsCell id={props.data[props.row.index].id} />,
    Cell: ({...props}) => <DeckSubscribeCell id={props.data[props.row.index].id} subscribed={props.data[props.row.index].subscribed}/>,
  },
]

export {decksColumns}
