// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { useIntl } from "react-intl";

type Props = {
    className: string
    chartHeight: string
    backGroundColor: string
    history?: number[]
    learningRate?: number
    streak?:number
}

const DashboardStreak: React.FC<Props> = ({ 
    className, 
    backGroundColor, 
    chartHeight = '150px', 
    history=[],
    learningRate=50,
    streak=0
}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const intl = useIntl()

    const daysOfWeek = [
        intl.formatMessage({ id: "DASHBOARD.STREAK.SUNDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.MONDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.TUESDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.WEDNESDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.THURSDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.FRIDAY" }), 
        intl.formatMessage({ id: "DASHBOARD.STREAK.SATURDAY" }), 
    ];
   
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, history, daysOfWeek, learningRate, intl.formatMessage({ id: "DASHBOARD.STREAK.LEARNED" }), intl.formatMessage({ id: "DASHBOARD.STREAK.WORDS" })))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, mode, history])

    return (
        <div
            className={`card ${className} theme-dark-bg-body`}
            style={{ backgroundColor: backGroundColor }}
        >
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
                {/* begin::Wrapper */}
                <div className='d-flex flex-column flex-grow-1'>
                    {/* begin::Title                    */}
                    <span className='text-white fw-bolder fs-1'>
                    {intl.formatMessage({ id: "DASHBOARD.STREAK.TITLE" })}
                    </span>
                    {/* end::Title */}
                    <div
                        ref={chartRef}
                        className='mixed-widget-14-chart'
                        style={{ height: chartHeight, minHeight: chartHeight }}
                    ></div>
                </div>
                {/* end::Wrapper */}

                {/* begin::Stats */}
                <div className='pt-5'>
                    {/* begin::Number */}
                    <span className='text-white fw-bolder fs-3x me-2 lh-0'>{streak}</span>
                    {/* end::Number */}

                    {/* begin::Symbol */}
                    <span className='text-white fw-bolder fs-2x lh-0'>{intl.formatMessage({ id: "DASHBOARD.STREAK.DAYS" })}</span>
                    {/* end::Symbol */}

                    {/* begin::Text */}
                    {/* <span className='text-white fw-bolder fs-6 lh-0'> - new record</span> */}
                    {/* end::Text */}
                </div>
                {/* end::Stats */}
            </div>
        </div>
    )
}

const chartOptions = (chartHeight: string, history: number[], daysOfWeek: string[], learningRate: number, learnedText: string, wordsText: string ): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-800')
    const today = new Date().getDay();

    var days = []
    if ( history ){
        for (let i = 0; i < history?.length; i++) {
            // Get the name of the day for (today + i) % 7
            days.unshift(daysOfWeek[((today - i%7) + 7) % 7]);
        }
    }

    return {
        series: [
            {
                name: learnedText,
                data: history.map((val)=>val+1),
            },
        ],
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false,
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
        },
        colors: history.map(value => value >= learningRate ? '#CDDC39' : '#ffffff'),
        plotOptions: {
            bar: {
                distributed: true,
                borderRadius: 3,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
                columnWidth: '10%',
            },
        },
        legend:{
            show: false
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return val + '%'
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            labels: {
                show: false,
            },
            categories: days,
            position: 'top',
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            show: false,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
                background: labelColor,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val-1 + ' '+ wordsText
                },
            },
        },
    }
}

export { DashboardStreak }
