import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InputFillWord } from '../../modules/apps/learn/vocabulary/task/InputFillWord'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const Hero = () => {
  const [inputValue, setInputValue] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState(false)

  useEffect(() => {
    const targetValue = 'beyond'
    let currentIndex = 0

    const interval = setInterval(() => {
      setInputValue((prevValue) => prevValue + targetValue[currentIndex++])
      if (currentIndex === targetValue.length) {
        clearInterval(interval)
        setCorrectAnswer(true)
      }
    }, 200)

    return () => clearInterval(interval)
  }, [])

  return (
    <section className='my-20'>
      <div className='container  text-center'>
        <h1 className='fs-4hx text-dark mb-4'>
          Master languages and{' '}
          <InputFillWord
            word={'beyond'}
            correctAnswer={correctAnswer}
            inputValue={inputValue}
            placeholder=''
            recording={false}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => void {}}
            checkAnswer={() => void {}}
            disabled={correctAnswer}
          />{' '}
          with <span> algerNoun.</span>
        </h1>
        <img
          alt='Logo'
          className='w-50 logo theme-dark-show my-20'
          style={{ minWidth: '300px' }}
          width="100%"
          height="100%"
          src={toAbsoluteUrl('/media/logos/algernoun-dark.webp')}
        />

        <img
          alt='Logo'
          className='w-50 logo theme-light-show my-20'
          style={{ minWidth: '300px', filter: 'contrast(0.1) brightness(0.5)' }}
          width="100%"
          height="100%"
          src={toAbsoluteUrl('/media/logos/algernoun-white.webp')}
        />

        <p className='text-center fs-1 mt-4 fw-normal'>
          Algernoun uses cutting-edge spaced memory techniques to streamline
          <br />
          your learning journey. Fill the gaps in your knowledge and store them
          <br />
          in the long-term memory space of your brain.
        </p>

        <Link to='/auth'>
          <div className='w-100 text-center mt-10'>
            <button className='btn btn-primary mt-3 fw-bolder fs-1'>Join the beta today</button>
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Hero
