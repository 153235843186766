// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from "react-intl";

type Props = {
  className: string
  backGroundColor: string
  done?: number
  out_of?: number
  disabled: boolean
}

const DashboardWidget1: FC<Props> = (
  {
    className,
    backGroundColor,
    done,
    out_of,
    disabled
  }) => {

  const intl = useIntl()
  let width = Math.min(Math.round((done && out_of ? (done / out_of) * 100 : 0)), 100) + "%";
  return (
    <div
      className={`card ${className} theme-dark-bg-body btn btn-secondary ${disabled && 'disabled'}`}
      style={{ backgroundColor: backGroundColor , cursor: 'pointer'}}
      data-bs-toggle='modal'
      data-bs-target='#kt_modal_learning_rate'
    >

      <div className="card-header py-5 border-bottom-0">

        <div className="card-title d-flex flex-column">

          <span className="fs-2hx text-dark me-2 lh-1 ls-n2">{intl.formatMessage({ id: "DASHBOARD.GOAL.TITLE" })}</span>

          <span className="text-dark opacity-50 pt-1 fw-semibold fs-3"><b>{done}/{out_of}</b> {intl.formatMessage({ id: "DASHBOARD.GOAL.CARDS" })}</span>

        </div>

      </div>


      <div className="card-body d-flex align-items-end pt-0">

        <div className="d-flex align-items-center flex-column mt-3 w-100">
          <div className="d-flex justify-content-between fs-5 text-dark opacity-50 w-100 mt-auto mb-2">
            <span>{(out_of - done)>0 ?(out_of - done) : 0} {intl.formatMessage({ id: "DASHBOARD.GOAL.PENDING" })}</span>
            <span>{width}</span>
          </div>
          <div className="h-8px mx-3 w-100 bg-light-danger rounded">
            <div className="bg-dark rounded h-8px" role="progressbar" style={{ width: width, transition: '0.4s' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" aria-label='Today progress'></div>
          </div>
        </div>

      </div>

    </div>
  );
}

export { DashboardWidget1 }
