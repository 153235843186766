/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../helpers'
import { useIntl } from 'react-intl'

const Levels: FC = () => {
    const intl = useIntl()


    const levels = [
        {
            width: "100%",
            background: "bg-success",
            text: 'LEARN.LEVEL.FIFTH'
        }
        ,
        {
            width: "80%",
            background: "bg-primary",
            text: 'LEARN.LEVEL.FOURTH'
        },
        {
            width: "60%",
            background: "bg-info",
            text: 'LEARN.LEVEL.THIRD'
        },
        {
            width: "40%",
            background: "bg-secondary",
            text: 'LEARN.LEVEL.SECOND'
        },
        {
            width: "20%",
            background: "bg-warning",
            text: 'LEARN.LEVEL.FIRST'
        }
    ]

    return (
        <div className='modal fade pt-20' id='kt_modal_levels_info' aria-hidden='true'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

                    <div className='modal-body  mx-5 mx-xl-18 pt-0 pb-5'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-5'>{intl.formatMessage({ id: "LEARN.LEVEL.TITLE" })}</h1>

                            <div className='fs-3'>
                                {intl.formatMessage({ id: "LEARN.LEVEL.DESCRIPTION" })}
                            </div>
                        </div>

                        <div className='separator d-flex flex-center mb-8'></div>

                        <div className='mb-10'>

                            <div className='mh-300px me-n7 pe-7'>
                                {levels.map((level, i) => {
                                    return (
                                        <div
                                            className='d-flex py-3 w-100 mw-275px m-auto'
                                            key={i}
                                        >
                                            <div className='d-flex px-5 mb-2'>
                                                <div className='progress h-10px w-100 my-auto' style={{ minWidth: "100px", maxWidth: "200px", boxShadow: "0px 0px 9px -4px var(--bs-secondary-bg)" }}>
                                                    <div
                                                        className={`progress-bar ${level.background}`}
                                                        role='progressbar'
                                                        style={{ width: level.width }}
                                                    ></div>
                                                </div>
                                            </div>

                                            <div className="fs-4 my-auto">
                                                {intl.formatMessage({ id: level.text })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export { Levels }
