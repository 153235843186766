import { FC, createContext, useCallback, useContext} from 'react';
import { DashboardDatResponse } from './_models';
import { WithChildren } from '../../helpers';
import { getDashboardData } from './_requests';

type DashboardDataProviderValue = {
  getDashboardData: () => Promise<DashboardDatResponse>;
};

const DashboardDataContext = createContext<DashboardDataProviderValue | undefined>(undefined);

const useDashboardData = (): DashboardDataProviderValue => {
    const context = useContext(DashboardDataContext);
    if (context === undefined) {
        throw new Error('useDashboardData must be used within a LearnVocabularyProvider');
    }
    return context;
};

const DashboardDataProvider: FC<WithChildren> = ({ children }) => {

    const getDashboardDataCallback = useCallback(getDashboardData, []);

    const value: DashboardDataProviderValue = {
      getDashboardData: getDashboardDataCallback,
    };


    return (
    <DashboardDataContext.Provider value={value}>
        {children}
    </DashboardDataContext.Provider>
    );
};

export { DashboardDataProvider, useDashboardData };
