import { createContext, useState } from "react";
import { WithChildren } from "../../../../../../_metronic/helpers";
import { InfoValue } from "./_models";

const InfoContext = createContext<InfoValue>({
  title: undefined,
  description: undefined,
  setInfoTitle: () => { },
  setInfoDescription: () => { },
});


const InfoProvide: React.FC<WithChildren> = ({ children }) => {
  const [title, setInfoTitle] = useState<string>();
  const [description, setInfoDescription] = useState<string>();

  return (
    <InfoContext.Provider value={{ title, description, setInfoTitle, setInfoDescription }}>
      {children}
    </InfoContext.Provider>
  );
};


export { InfoProvide, InfoContext };
