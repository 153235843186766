/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Deck} from '../../core/_models'

type Props = {
  deck: Deck
}

const DeckInfoCell: FC<Props> = ({deck}) => (
  <div className='d-flex align-items-center text-start'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {deck.image ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`${deck.image}`)} alt={deck.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${deck.initials?.state}`,
              `text-${deck.initials?.state}`
            )}
          >
            {deck.initials?.label}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column' style={{minWidth :  '100px'}}>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {deck.name}
      </a>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>{deck.size} words</span>
    </div>
  </div>
)

export {DeckInfoCell}
