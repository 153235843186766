import {FC} from 'react'
// import * as Yup from 'yup'
// import {useFormik} from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Deck} from '../core/_models'

type Props = {
  isDeckLoading: boolean
  deck: Deck
}

const DeckEditModalForm: FC<Props> = ({deck, isDeckLoading}) => {
 return <></>
}

export {DeckEditModalForm}
