import {FC, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {decksColumns} from './columns/_columns'
import {Deck} from '../core/_models'
import {DecksListLoading} from '../components/loading/DecksListLoading'
import {DecksListPagination} from '../components/pagination/DecksListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

import useWindowSize from './hooks/useWindowSize';

const DecksTable = () => {
  const intl = useIntl()
  const size = useWindowSize();
  const decks = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => decks, [decks]);
  const columns = useMemo(() => decksColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  if (size.width < 768) {
    return (
      <div>
        {rows.map((row: Row<Deck>, i) => {
          prepareRow(row);
          return <CardView row={row} key={`row-${i}-${row.id}`} />
        })}
      </div>
    );
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_decks'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Deck>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Deck>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({ id: "DECKS.TABLE.NORECORDS" })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <DecksListPagination />
      {isLoading && <DecksListLoading />}
    </KTCardBody>
  )
}
type Props = {
  row : Row<Deck>
}
const CardView: FC<Props> = ({ row }) => {
  return (
    <div className="card d-flex justify-content-center align-items-center bg-secondary mx-4 my-2 p-0">
      <div className="card-body d-flex flex-column align-items-center">
        {row.cells.map((cell, idx) => (
          <div key={idx} className="d-flex justify-content-center my-2 fs-3">
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    </div>
  );
};




export {DecksTable}
