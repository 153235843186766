import { toAbsoluteUrl } from "../../../_metronic/helpers";

const Feature2 = () => {
    return (
        <div className="container">
            <h1 className="fs-2hx text-dark mb-10">Create impactful learning experiences with ease</h1>

            <ul className="nav nav-pills mb-3 row" id="pills-tab" role="tablist">
                <li className="nav-item col-md-3 mb-5 mx-0" role="presentation">
                    <div className="active card h-100 p-1  btn btn-outline-light" id="pills-first-tab" data-bs-toggle="pill" data-bs-target="#pills-first" role="tab" aria-controls="pills-first" aria-selected="true">
                        <div className="card-body d-flex flex-column text-start">
                            <span className="fw-bold fs-4 mb-2">Language Learning</span>
                            <span>Dive into a unique language learning experience with our rich content across diverse languages.</span>
                        </div>
                    </div>
                </li>
                <li className="nav-item col-md-3 mb-5 mx-0" role="presentation">
                    <div className="card h-100 p-1  btn btn-outline-light" id="pills-second-tab" data-bs-toggle="pill" data-bs-target="#pills-second" role="tab" aria-controls="pills-second" aria-selected="true">
                        <div className="card-body d-flex flex-column text-start">
                            <span className="fw-bold fs-4 mb-2">Interactive Decks</span>
                            <span>Explore a diverse range of topics, all created with the intent to personalize your learning journey.</span>
                        </div>
                    </div>
                </li>
                <li className="nav-item col-md-3 mb-5 mx-0" role="presentation">
                    <div className="card h-100 p-1  btn btn-outline-light" id="pills-third-tab" data-bs-toggle="pill" data-bs-target="#pills-third" role="tab" aria-controls="pills-third" aria-selected="true">
                        <div className="card-body d-flex flex-column text-start">
                            <span className="fw-bold fs-4 mb-2">Memory Techniques</span>
                            <span>Apply the proven spaced repetition technique to effectively remember what you've learned.</span>
                        </div>
                    </div>
                </li>
                <li className="nav-item col-md-3 mb-5 mx-0" role="presentation">
                    <div className="card h-100 p-1  btn btn-outline-light" id="pills-fourth-tab" data-bs-toggle="pill" data-bs-target="#pills-fourth" role="tab" aria-controls="pills-fourth" aria-selected="true">
                        <div className="card-body d-flex flex-column text-start">
                            <span className="fw-bold fs-4 mb-2">Text Analysis & Custom Decks</span>
                            <span>Dissect texts, grasp context, save words, and create custom study decks.</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-first" role="tabpanel" aria-labelledby="pills-first-tab">
                    <div className="card  card-xl-stretchmb-10">
                        <div className="card-body p-0">
                            <img width="100%"
                                height="100%"
                                src={toAbsoluteUrl('/media/screens/dashboard.webp')} className="w-100 rounded border border-light" alt='Language Learning Interface' />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="pills-second" role="tabpanel" aria-labelledby="pills-second-tab">
                    <div className="card  card-xl-stretchmb-10">
                        <div className="card-body p-0">
                            <img width="100%"
                                height="100%"
                                src={toAbsoluteUrl('/media/screens/decks.webp')} className="w-100 rounded border border-light"
                                alt='Interactive Decks Interface' />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="pills-third" role="tabpanel" aria-labelledby="pills-third-tab">
                    <div className="card  card-xl-stretchmb-10">
                        <div className="card-body p-0">
                            <img width="100%"
                                height="100%"
                                src={toAbsoluteUrl('/media/screens/learn.webp')} className="w-100 rounded border border-light"
                                alt='Memory Techniques Interface' />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="pills-fourth" role="tabpanel" aria-labelledby="pills-fourth-tab">
                    <div className="card  card-xl-stretchmb-10">
                        <div className="card-body p-0">
                            <img width="100%"
                                height="100%"
                                src={toAbsoluteUrl('/media/screens/analysis.webp')} className="w-100 rounded border border-light"
                                alt='Progress Tracking Interface' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feature2;

