/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { AlgernounInitialForm } from '../../_metronic/layout/core/AlgernounInitialForm'
import { AboutUsWrapper } from '../pages/about-us/AboutUsWrapper'
import { LandingPage } from '../pages/landing/LandingPage'
import { TranslatorWrapper } from '../modules/apps/analysis/translator/TranslatorWrapper'
import Navigation from '../pages/landing/Navigation'
import { PrivacyPolicyWrapper } from '../pages/privacy-policy/PrivacyPolicyWrapper'
import { TermsOfServiceWrapper } from '../pages/tos/Tos'
import { GuestDashboardWrapper } from '../pages/guest/GuestDashboardWrapper'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='about-us' element={<AboutUsWrapper />} />
          <Route path='privacy-policy' element={<PrivacyPolicyWrapper />} />
          <Route path='terms' element={<TermsOfServiceWrapper />} />
          <Route path='analysis' element={
            <>
              <Navigation sticky={false} />
              <div className='container'>
                <div className="py-5">
                  <TranslatorWrapper />
                </div>
              </div>
            </>
          }
          />
          <Route path='/' element={<LandingPage />} />
          
          {currentUser ? (
            currentUser.lang_origin_id == null ?
              <Route path='/*' element={<AlgernounInitialForm />} /> :
              (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              )
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='/dashboard' element={<GuestDashboardWrapper />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
