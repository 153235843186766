/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC } from 'react'
import { InputFillWord } from './InputFillWord'

type Props = {
  context?: any
  word: string
  correctAnswer: boolean
  inputValue: string
  placeholder: string
  recording: boolean
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  checkAnswer: () => void
  disabled: boolean
}

const FillWord: FC<Props> = ({
  context,
  word,
  correctAnswer,
  inputValue,
  placeholder,
  recording,
  handleInputChange,
  checkAnswer,
  disabled,
}) => {
  // Normalize the lemma for matching
  const normalizedLemma = word.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Normalize the entire context for matching
  const normalizedContext = context.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Use regex to find all occurrences of the lemma in the normalized context
  const regex = new RegExp('\\b' + normalizedLemma + '\\b', 'gi');

  const elements: any[] = [];
  let lastIndex = 0;

  // Iterate over all matches in the normalized context
  normalizedContext.replace(regex, (match: any, index: number) => {
    // Map index back to original context
    // This works if normalization doesn't change text length
    elements.push(<span>{context.slice(lastIndex, index)}</span>);

    // Add the InputFillWord component for the match in original context
    elements.push(
      <InputFillWord
        word={context.slice(index, index + match.length)}
        correctAnswer={correctAnswer}
        inputValue={inputValue}
        placeholder={placeholder}
        recording={recording}
        handleInputChange={handleInputChange}
        checkAnswer={checkAnswer}
        disabled={disabled}
      />
    );

    lastIndex = index + match.length;
    return match;
  });

  // Add any remaining text after the last match in original context
  if (lastIndex < context.length) {
    elements.push(<span>{context.slice(lastIndex)}</span>);
  }

  return (
    <div className='fill-word pb-5'>
      {elements.map((element, index) => (
        <span key={index} className='display-6 fw-normal text-primary'>
          {element}
        </span>
      ))}
    </div>
  );
}

export { FillWord }
