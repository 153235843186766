import {useCallback, useEffect} from 'react'
import {setLanguage, useLang} from '../../../_metronic/i18n/Algernoun18n'
import {useAuth} from '../../modules/auth'
import {getUserByToken, login} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'

const GuestDashboardWrapper = () => {
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const locale = useLang()

  const guestLogin = useCallback(async () => {
    try {
      const {data: auth} = await login('guest@gmail.com', '123456')
      saveAuth(auth)
      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      if (user.language !== locale) {
        // console.log('difference')
        setLanguage(user.language)
        
      }
      navigate('/dashboard');
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
    }
  }, [locale, saveAuth, setCurrentUser, navigate])

  useEffect(() => {
    guestLogin()
  }, [guestLogin])

  return null
}

export {GuestDashboardWrapper}
