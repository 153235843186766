/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useLearnVocabulary } from '../core/LearnVocabularyProvider'
import { useNotifications } from '../../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'

type Props = {
  word_id: string,
  is_favorite: boolean
}

const DropdownWord: FC<Props> = ({ word_id , is_favorite}) => {
  
  const intl = useIntl()
  const { addFavoriteWord } = useLearnVocabulary();
  const [isFavorite, setIsFavorite] = useState(is_favorite ?? false);
  const { showNotification} = useNotifications();

  useEffect(()=>{
    setIsFavorite(is_favorite)
  },[is_favorite])


  async function addFavorite(){
    addFavoriteWord({ word_id , is_favorite})
    .then((response) =>{
      if (response){
        setIsFavorite(!isFavorite)
      }
    })
    .catch((error)=>{
      showNotification("error", error.toString())
    })
    
  }
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-gray-600 menu-state-bg-light-primary w-200px p-0'
      data-kt-menu='true'
    >
      <div className='menu-item p-0' onClick={addFavorite}>
        <a href='#' className='menu-link fs-2 px-3 rounded-top'>
          {isFavorite &&  <KTSVG
            className="text-primary svg-icon svg-icon-1 mx-3"
            path="/media/icons/duotune/general/gen030.svg"
          />}
         {!isFavorite &&  <KTSVG
            className="text-dark svg-icon svg-icon-1 mx-3"
            path="/media/icons/duotune/general/gen030-2.svg"
          />}
          {intl.formatMessage({ id: "LEARN.SETTINGS.FAVORITE" })}
        </a>
      </div>
      <div className='menu-item p-0'>
        <a href='#' className='menu-link fs-2 px-3' data-bs-toggle='modal'
            data-bs-target='#kt_modal_inform_problem'>
        <KTSVG
            className="text-dark svg-icon svg-icon-1 mx-3"
            path="/media/icons/duotune/coding/cod009.svg"
          />
          {intl.formatMessage({ id: "LEARN.SETTINGS.PROBLEM" })}
        </a>
      </div>
      <div className='menu-item p-0'>
        <a href='#' className='menu-link fs-2 px-3 rounded-bottom'>
        <KTSVG
            className="text-dark svg-icon svg-icon-1 mx-3"
            path="/media/icons/duotune/general/gen046.svg"
          />
          Atajos de teclado
        </a>
      </div>
    </div>
  )
}

export { DropdownWord }
