import React, { FC, useCallback, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useNotifications } from '../../../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

type Props = {
  language: string;
  setInputValue: (text: string) => void;
  stopRecording: boolean;
  setRecording: (state:boolean) => void;
  disabled:boolean;
}

const Dictaphone: FC<Props> = ({language, setInputValue, stopRecording, setRecording, disabled }) => {
  const intl = useIntl()
  const {
    transcript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { showNotification } = useNotifications();

  const toggleListening = () => {
    if (listening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startListening = () => {
    setRecording(true);
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true, language: language });
  };

  const stopListening = useCallback(() => {
    SpeechRecognition.stopListening();
    setRecording(false);
  }, [setRecording]);

  useEffect(() => {
    let tokens = transcript.split(" ");
    setInputValue(tokens[tokens.length - 1].toLocaleLowerCase());

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (listening) {
        stopListening();
        if (transcript === "") {
          showNotification("error", "No hemos detectado nada en el micronofono")
        }
      }
    }, 6000); // Stop listening after 5 seconds of inactivity

  }, [transcript, setInputValue, listening, stopListening, showNotification]);

  useEffect(() => {
    // console.log(stopRecording);
    if (stopRecording) {
      stopListening();
    }
  }, [stopRecording, stopListening]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <>
      <button
        type='button'
        aria-label='Use Microphone'
        className='rounded btn btn-sm btn-icon btn-color-gray600 btn-active-secondary d-flex align-items-center ml-4 p-3'
        onClick={toggleListening}
      >
        {listening ? <i className='fas fa-stop text-danger fs-2'></i> : <i className='fas fa-microphone fs-2'></i>}
      </button>
      {listening && <span className='my-auto mx-2'>{intl.formatMessage({ id: "LEARN.BELT.RECORDING" })}...</span>}
    </>
  );
};

export default Dictaphone;
