// import { useIntl } from 'react-intl';
import Navigation from '../landing/Navigation'

const PrivacyPolicyPage = () => {
  return (
    <>
      <Navigation sticky={true} />

      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='container-xxl' id='kt_content_container'>
          <div className='card'>
            <div className='card-body p-lg-17'>
              <div className='mb-18'>
                <div className='mb-10'>
                  <div className='text-center mb-15'>
                    <h3 className='fs-2hx text-dark mb-5'>Algernoun - Privacy Policy</h3>
                    <div className='fs-5 text-muted fw-semibold'>Last Updated: July 8, 2023</div>
                  </div>
                </div>
                <div className='fs-5 fw-semibold text-gray-600 mw-800px m-auto text-justify'>
                  <h4 className='fs-2 text-dark mb-5'>Introduction</h4>

                  <p className='mb-8 fs-4'>
                    Welcome to Algernoun ("we", "us", or "our"). Your privacy is of utmost
                    importance to us. This Privacy Policy is designed to explain what information we
                    collect from you, how we use it, and how we comply with the necessary legal
                    requirements, including but not limited to Google's AdSense program.
                  </p>
                  <p className='mb-8 fs-4'>
                    Please read this Privacy Policy carefully. By using or accessing Algernoun ("our
                    Platform"), you acknowledge that you have read, understood, and agreed to be
                    bound by the terms of this Privacy Policy.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>1. Information Collection</h5>
                  <p className='mb-8 fs-4'>
                    When using our Platform, we may collect the following types of information:
                  </p>
                  <p className='mb-8 fs-4'>
                    1.1. Personal Information: Personal information that identifies you as an
                    individual, such as your name, email address, and other contact information
                    which you provide to us directly.
                  </p>
                  <p className='mb-8 fs-4'>
                    1.2. Non-Personal Information: Information that does not directly identify you
                    as an individual, such as your browser type, IP address, device information, the
                    pages you visit on our Platform, and other anonymous data.
                  </p>
                  <p className='mb-8 fs-4'>
                    1.3. Usage Information: Information related to your usage of our Platform, such
                    as the time and date of your visits, the features you use, your preferences, and
                    your interaction with advertisements.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>2. How We Use Your Information</h5>
                  <p className='mb-8 fs-4'>
                    2.1. We may use your information to provide, maintain, and improve our services,
                    including personalizing your user experience.
                  </p>
                  <p className='mb-8 fs-4'>
                    2.2. We may use your information for our marketing and advertising activities,
                    such as displaying relevant advertisements and promotional material.
                  </p>
                  <p className='mb-8 fs-4'>
                    2.3. We may use your information for compliance with legal obligations, to
                    respond to legal process, or as may be required for the operation of our Web
                    App.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>3. Information Sharing</h5>
                  <p className='mb-8 fs-4'>
                    We do not sell your personal information to third parties. However, we may share
                    your information with trusted third parties in the following circumstances:
                  </p>
                  <p className='mb-8 fs-4'>
                    3.1. Service Providers: We may share your information with third-party service
                    providers that perform services on our behalf, such as hosting services, data
                    analysis, and advertising services.
                  </p>
                  <p className='mb-8 fs-4'>
                    3.2. Compliance with Law: We may disclose your information if required by law,
                    in response to legal process, and law enforcement requests, or if we believe
                    that disclosure is necessary for the protection of our rights, your safety, or
                    the safety of others.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>4. Google AdSense</h5>
                  <p className='mb-8 fs-4'>
                    4.1. We use Google AdSense to display ads on our Platform. Google AdSense may use
                    your information to provide advertisements about goods and services that may be
                    of interest to you.
                  </p>
                  <p className='mb-8 fs-4'>
                    4.2. Google uses cookies to collect data in the ad serving process. You can
                    choose to disable cookies in your browser settings. However, this may affect
                    your ability to use our Platform.
                  </p>
                  <p className='mb-8 fs-4'>
                    4.3. Google's use of advertising cookies enables it and its partners to serve
                    ads to our users based on their visit to our site and/or other sites on the
                    Internet.
                  </p>
                  <p className='mb-8 fs-4'>
                    4.4. Users may opt out of personalized advertising by visiting Google's Ads
                    Settings.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>5. Your Rights and Choices</h5>
                  <p className='mb-8 fs-4'>
                    5.1. You may have certain rights relating to your personal information under
                    local data protection laws. These may include the right to access, correct,
                    delete, restrict the use of, or receive a copy of your personal information, or
                    to object to the processing of your personal information.
                  </p>
                  <p className='mb-8 fs-4'>
                    5.2. You can adjust your preferences and unsubscribe from our emails at any
                    time.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>6. Security</h5>
                  <p className='mb-8 fs-4'>
                    We take reasonable steps to protect your information from loss, misuse,
                    unauthorized access, disclosure, alteration, or destruction. However, no
                    Internet or e-mail transmission is ever fully secure or error-free. Please keep
                    this in mind when disclosing any personal information to us.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>7. Changes to the Privacy Policy</h5>
                  <p className='mb-8 fs-4'>
                    We may change this Privacy Policy from time to time. The updated Privacy Policy
                    will be posted on our Platform with the update date noted at the beginning of the
                    Policy. Please check back periodically to review any changes.
                  </p>
                  <h5 className='fs-2 text-dark mb-10'>8. Contact Us</h5>
                  <p className='mb-8 fs-4'>
                    If you have any questions about this Privacy Policy, please contact us at
                    support@algernoun.com . Your use of our Platform is subject to our Terms
                    of Service. Thank you for choosing Algernoun.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PrivacyPolicyWrapper = () => {
  return <PrivacyPolicyPage />
}

export {PrivacyPolicyWrapper}
