/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { subscribeDeck } from '../../core/_requests'
import { useIntl } from 'react-intl'

type Props = {
    id: ID
    subscribed: boolean
}

const DeckSubscribeCell: FC<Props> = ({ id, subscribed }) => {
    const intl = useIntl()
    const { query } = useQueryResponse()
    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const toggleSubscribeItem = useMutation(() => subscribeDeck(id), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        },
    })

    return (
        <>
            {!subscribed ?
                <a
                    className='btn btn-light-success px-3 w-75'
                    data-kt-users-table-filter='delete_row'
                    style={{minWidth: 'fit-content'}}
                    onClick={async () => await toggleSubscribeItem.mutateAsync()}
                >
                    {intl.formatMessage({ id: "DECKS.TABLE.BUTTONS.SUBSCRIBE" })}
                </a> :
                <a
                    className='btn btn-light-danger px-3 w-75'
                    data-kt-users-table-filter='delete_row'
                    style={{minWidth: 'fit-content'}}
                    onClick={async () => await toggleSubscribeItem.mutateAsync()}
                >
                    {intl.formatMessage({ id: "DECKS.TABLE.BUTTONS.UNSUBSCRIBE" })}
                </a>}
        </>

    )
}

export { DeckSubscribeCell }
