import {FC} from 'react'

type Props = {
  language?: string
}

const DeckLanguageCell: FC<Props> = ({language}) => (
  <div className='badge badge-light fw-bolder'>{language}</div>
)

export {DeckLanguageCell}
