// import { useIntl } from 'react-intl';
// import { EnableHeader, EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import {
    AboutUsIntro,
    AboutUsPublications,
    //   AboutUsTeam,
    AboutUsNetwork
} from '../../../_metronic/partials/widgets'
import Navigation from '../landing/Navigation';

const AboutUsPage = () => {

    return (
        <>
        <Navigation sticky={true}/>

        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container-xxl" id="kt_content_container">
                <div className="card">
                    <div className="card-body p-lg-17">
                        <AboutUsIntro className={''} backGroundColor={''} />
                        <AboutUsPublications className={''} backGroundColor={''} />
                        {/* <AboutUsTeam className={''} backGroundColor={''}/> */}
                        <AboutUsNetwork className={''} backGroundColor={''} />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

const AboutUsWrapper = () => {
    return (
        <AboutUsPage />
    );
};

export { AboutUsWrapper };
