import { FC, createContext, useCallback, useContext } from 'react';
import {  AddExampleLogInput, AddFavoriteInput, LearnDataResponse, setUserProgressInput } from './_models';
import { WithChildren } from '../../../../../../_metronic/helpers';
import { getLearnVocabularyData, addFavoriteWord, setUserProgress, addExampleLog } from './_requests'

type LearnVocabularyProviderValue = {
    
    getLearnVocabularyData: () => Promise<LearnDataResponse>;
    addFavoriteWord: (input: AddFavoriteInput) => Promise<Boolean>;
    setUserProgress: (input: setUserProgressInput) => Promise<Boolean>;
    addExampleLog: (input: AddExampleLogInput) => Promise<Boolean>;
};

const LearnVocabularyContext = createContext<LearnVocabularyProviderValue | undefined>(undefined);

const useLearnVocabulary = (): LearnVocabularyProviderValue => {
    const context = useContext(LearnVocabularyContext);
    if (context === undefined) {
        throw new Error('useLearnVocabulary must be used within a LearnVocabularyProvider');
    }
    return context;
};

const LearnVocabularyProvider: FC<WithChildren> = ({ children }) => {
    const getLearnVocabularyDataCallback = useCallback(getLearnVocabularyData, []);
    const addFavoriteWordCallback = useCallback(addFavoriteWord, []);
    const addExampleLogCallback = useCallback(addExampleLog, []);
    
    const setUserProgressCallback = useCallback(setUserProgress, []);

    const value: LearnVocabularyProviderValue = {
        getLearnVocabularyData: getLearnVocabularyDataCallback,
        addFavoriteWord: addFavoriteWordCallback,
        setUserProgress: setUserProgressCallback,
        addExampleLog : addExampleLogCallback,
    };

    return (
    <LearnVocabularyContext.Provider value={value}>
        {children}
    </LearnVocabularyContext.Provider>
    );
};

export { LearnVocabularyProvider, useLearnVocabulary };
