/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useLayoutEffect} from 'react'
import {WithChildren} from '../../helpers'
import {useLayout} from './LayoutProvider'


const EnableHeader: FC<WithChildren> = ({children}) => {

    const {config} = useLayout()
    const {header} = config

  useLayoutEffect(() => {
    if (header.display) {
        document.getElementById('kt_header_container')?.classList.remove('d-xl-none')
        document.getElementById('kt_header_container')?.classList.remove('d-lg-none')
      }
    }, [header.display])
  
    return <>{children}</>
}

const DisableHeader: FC<WithChildren> = ({children}) => {
    useLayoutEffect(() => {
      document.getElementById('kt_header_container')?.classList.add('d-xl-none')
      document.getElementById('kt_header_container')?.classList.add('d-lg-none')
    }, [])
  
    return <>{children}</>
  }
export {EnableHeader, DisableHeader}
