import { KTSVG } from "../../../helpers";

type Props = {
    level: number
}

const DifficultyIcons: React.FC<Props> = ({ level }) => {

    const icons = [];

    for (let i = 0; i < level; i++) {
        icons.push(
            <KTSVG
                key={i}
                path='/media/icons/duotune/general/gen029.svg'
                className='svg-icon-3 svg-icon-primary'
            />
        );
    }

    return (
        <span className='text-muted fw-semibold d-block fs-7'>
            {icons}
        </span>
    );
}


export {DifficultyIcons}