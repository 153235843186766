// import { useIntl } from 'react-intl';
import Navigation from '../landing/Navigation'

const TermsOfServicePage = () => {
  return (
    <>
      <Navigation sticky={true} />

      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='container-xxl' id='kt_content_container'>
          <div className='card'>
            <div className='card-body p-lg-17'>
              <div className='mb-18'>
                <div className='mb-10'>
                  <div className='text-center mb-15'>
                    <h3 className='fs-2hx text-dark mb-5'>Algernoun - Terms and Conditions</h3>
                    <div className='fs-5 text-muted fw-semibold'>Last Updated: July 8, 2023</div>
                  </div>
                </div>
                <div className='fs-5 fw-semibold text-gray-600 mw-800px m-auto text-justify'>
                  <h4 className='fs-2 text-dark mb-5'>Acceptance of Terms</h4>

                  <p className='mb-8 fs-4'>
                    By accessing and using the Algernoun web application ("Platform"), you agree to
                    these Terms and Conditions ("Terms") and our Privacy Policy. If you do not agree
                    with these Terms, you should not use our Platform.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Description of Services</h5>
                  <p className='mb-8 fs-4'>
                    We provide a language learning platform that uses spaced repetition and machine
                    learning techniques. We offer features such as language learning tools,
                    personalized topic decks, and a wide variety of content to support your learning
                    journey. While we strive to provide accurate and up-to-date resources, we cannot
                    guarantee the validity and accuracy of all content on our platform.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Use of the Platform</h5>
                  <p className='mb-8 fs-4'>
                    You agree to use the Platform for personal, non-commercial use only. You must not
                    reproduce, distribute, modify, create derivative works of, publicly display,
                    publicly perform, republish, download, store, or transmit any of the material on
                    our Platform, except as necessary to use the Platform for its intended purposes.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>User-Generated Content</h5>
                  <p className='mb-8 fs-4'>
                    Any content you upload or otherwise contribute to the Platform is considered
                    non-confidential and non-proprietary. You grant Algernoun a worldwide,
                    royalty-free license to use, reproduce, modify, perform, display, distribute,
                    and otherwise disclose such content for any purpose.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Prohibited Conduct</h5>
                  <p className='mb-8 fs-4'>
                    You agree not to engage in any conduct that is harmful, fraudulent, deceptive,
                    threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise
                    objectionable while using our Platform.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Advertisements</h5>
                  <p className='mb-8 fs-4'>
                    Our Platform is funded by advertising revenue and may display advertisements and
                    promotions. The manner, mode, and extent of advertising by Algernoun are subject
                    to change without notice.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Modification of Terms</h5>
                  <p className='mb-8 fs-4'>
                    We may revise these Terms from time to time. We will provide notice of any
                    changes by updating the "Last Updated" date of these Terms and any changes will
                    be effective upon posting.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Disclaimer</h5>
                  <p className='mb-8 fs-4'>
                    While we strive to provide an accurate, safe, and engaging language learning
                    experience, our Platform is provided on an "as is" and "as available" basis. We
                    disclaim all warranties of any kind, whether express or implied, including but
                    not limited to the accuracy, reliability, and suitability of our Platform for any
                    purpose.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Limitation of Liability</h5>
                  <p className='mb-8 fs-4'>
                    To the maximum extent permitted by law, Algernoun shall not be liable for any
                    indirect, incidental, special, consequential, or punitive damages, or any loss
                    of profits or revenues, whether incurred directly or indirectly, resulting from
                    your use of the Platform or any content obtained from the Platform.
                  </p>

                  <h5 className='fs-2 text-dark mb-10'>Contact Us</h5>
                  <p className='mb-8 fs-4'>
                    If you have any questions about these Terms, please contact us at
                    support@algernoun.com. Your use of our Platform is also subject to our Privacy
                    Policy. Thank you for choosing Algernoun.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TermsOfServiceWrapper = () => {
  return <TermsOfServicePage />
}

export {TermsOfServiceWrapper}
