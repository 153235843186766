import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useThemeMode } from "../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { useAuth } from '../../modules/auth'
import { FC } from "react";


type Props = {
  sticky: boolean
}

const Navigation: FC<Props> = ({ sticky }) => {
  const { mode } = useThemeMode()
  const { currentUser } = useAuth()

  return (
    <div className={sticky ? `sticky-top` : ''}  data-kt-menu='true' style={{ backgroundColor: 'var(--bs-page-bg)' }}>
      <nav className="container-xxl navbar navbar-expand-md h-100px text-light">
        <Link to='/'>
          {mode === 'light' && (
            <div className="btn btn-outline-light  px-0">
              <img
                alt='Logo'
                width="100px"
                height="100%"
                className='w-100px logo theme-light-show'
                src={toAbsoluteUrl('/media/logos/algernoun.svg')}
              />
            </div>
          )}

          {mode === 'dark' && (
            <div className="btn btn-outline-dark  px-0">
              <img
                alt='Logo'
                width="100px"
                height="100%"
                className='w-100px logo theme-dark-show'
                src={toAbsoluteUrl('/media/logos/algernoun-dark.svg')}
              />
            </div>
          )}
        </Link>

        <div className="collapse navbar-collapse w-100 justify-content-center" id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="https://github.com/hamzabealta/algernoun.git" target="_blank" rel="noreferrer" className="nav-link btn text-dark fs-3 fw-bolder">
                Github
              </a>
            </li>
            <li className="nav-item">
              <a href="https://instagram.com/algernoun" target="_blank" rel="noreferrer" className="nav-link btn text-dark fs-3 fw-bolder">
                Instagram
              </a>
            </li>

            <li className="nav-item">
              <a href="https://twitter.com/algernoun" target="_blank" rel="noreferrer" className="nav-link btn text-dark fs-3 fw-bolder">
                Twitter
              </a>
            </li>
            <li className="nav-item">
              <a href="https://www.tiktok.com/@algernoun?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer" className="nav-link btn text-dark fs-3 fw-bolder">
                TikTok
              </a>
            </li>
          </ul>
        </div>
        {currentUser ? (

          <Link to='/'>
            <div className="btn btn-light-secondary text-dark ml-md-3 fs-3 fw-bolder w-100">
              App
            </div>
          </Link>
        ) :
          (
            <div className="d-flex">

              {sticky && <Link to='/analysis' className="nav-link btn fs-3 fw-normal text-primary" style={{ width: 'max-content' }}>
                Try Analysis
              </Link>}
              <Link to='/auth'>
                <div className="btn btn-light-secondary text-dark ml-md-3 fs-3 fw-bolder w-100">
                  Login
                </div>
              </Link>
            </div>

          )
        }
      </nav>
    </div>
  );
};

export default Navigation;