import { FC } from "react";
import { LearnDataModel } from "../core/_models";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';

interface StatsWidgetProps {
    className?: string;
    learnData: LearnDataModel;
    todayProgress: number;
}

const StatsWidget: FC<StatsWidgetProps> = ({ className, learnData, todayProgress }) => {
    const { learningRate } = learnData;
    const intl = useIntl();

    return (
        <>
            {todayProgress < learningRate
                ?
                <div className={`card ${className} py-10`}>
                    <div className="card-body pb-0 d-flex flex-column align-items-center justify-content-center">
                        <h1 className="display-4 text-center text-dark mb-4">{intl.formatMessage({ id: 'LEARN.MISSINGDATA.TITLE' })}</h1>
                        <p className="lead text-center mb-4 fs-2">{intl.formatMessage({ id: 'LEARN.MISSINGDATA.MESSAGE' })}</p>
                        <p className="mb-4 text-center"><strong>{intl.formatMessage({ id: 'LEARN.MISSINGDATA.PROGRESS' })}: </strong> <span className="display-5">{todayProgress}</span>/<span className="display-5">{learningRate}</span></p>
                        <p className="mb-4 text-center fs-5">{intl.formatMessage({ id: 'LEARN.MISSINGDATA.SUBSCRIBE_PROMPT' })}</p>
                        <Link to='/apps/deck-management/decks' className='d-flex align-items-center justify-content-center mb-5'>     
                           <button type="button" className="btn btn-light-primary btn-lg border-primary mt-5">{intl.formatMessage({ id: 'LEARN.MISSINGDATA.SUBSCRIBE_BUTTON' })}</button>
                        </Link>
                    </div>
                </div>
                :
                <div className={`card ${className}`}>
                    <div className="card-body pb-0 d-flex flex-column align-items-center justify-content-center">
                        <h1 className="display-4 text-center text-dark mb-4">{intl.formatMessage({ id: 'LEARN.COMPLETE.TITLE' })}</h1>
                        <p className="lead text-center mb-4">{intl.formatMessage({ id: 'LEARN.COMPLETE.MESSAGE' })}</p>
                        <div className="row w-75">
                            <div className="col-sm-6 text-center">
                                <p className="text-muted mb-1 fs-4">{intl.formatMessage({ id: 'LEARN.COMPLETE.TOTALCARDS' })}:</p>
                                <h2 className="display-5 text-success">{todayProgress}</h2>
                            </div>
                        
                            <div className="col-sm-6 text-center fs-4">
                                <p className="text-muted mb-1">{intl.formatMessage({ id: 'LEARN.COMPLETE.LEARNINGRATE' })}:</p>
                                <h2 className="display-5">{learningRate}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export { StatsWidget }
