import {useIntl} from 'react-intl'
import {
  EnableHeader,
  EnableSidebar,
  PageTitle,
  useNotifications,
} from '../../../_metronic/layout/core'
import {
  DashboardDataProvider,
  useDashboardData,
} from '../../../_metronic/layout/core/DashboardDataProvider'
import {
  DashboardWidget1,
  DashboardStreak,
  DashboardDecks,
  DashboardAdds,
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../modules/auth'
import {DashboardDataModel} from '../../../_metronic/layout/core/_models'
import {useCallback, useEffect, useState} from 'react'
import {LearningRate} from '../../../_metronic/partials/modals/user-settings/LearningRate'
import { Loading } from '../../../_metronic/helpers'

const DashboardPage = () => {
  const {showNotification} = useNotifications()
  const [loading, setLoading] = useState(false)
  const {getDashboardData} = useDashboardData()
  const [dashboardData, setDashboardData] = useState<DashboardDataModel>({
    user: {
      name: '',
      uuid: '',
    },
    language: {
      id: 1,
      code: 'en',
      text: 'English',
    },
    progress: {
      total_subscribed_words: 0,
      total_learned_words: 0,
      total_learning_words: 0,
    },
    today: {
      goal_words: 0,
      seen: 0,
    },
    history: {
      data: [],
      streak: 0,
    },
    decks: [],
  })

  const fetchData = useCallback(async () => {
    setLoading(true)
    const response = await getDashboardData()
    setLoading(false)
    if (response.data) {
      setDashboardData(response.data)
    } else {
      showNotification('error', 'Something went wrong')
    }
  }, [getDashboardData, setDashboardData, showNotification])

  const settingsChange = (msg: string) => {
    showNotification('success', msg)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      {loading && <Loading />}
      
      <div className='row gy-5 g-xl-10 mb-5 mb-xl-0'>
        {/*begin::Col*/}
        <div className='col-xl-4'>
          <DashboardWidget1
            className='card-xl-stretch mb-xl-10'
            backGroundColor='#fcfcfb'
            done={dashboardData.today.seen}
            out_of={dashboardData.today.goal_words}
            disabled={loading}
          />

          {dashboardData.today.goal_words > 0 && (
            <LearningRate range={dashboardData.today.goal_words} onChangeHandler={settingsChange} />
          )}
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-xl-8'>
          <DashboardStreak
            className='card-xl-stretch mb-xl-10'
            backGroundColor='#74b0dc'
            chartHeight='100px'
            history={dashboardData.history.data}
            learningRate={dashboardData.today.goal_words}
            streak={dashboardData.history.streak}
          />
        </div>
        {/*end::Col*/}
      </div>
      {/*end::Row*/}
      <div className='row gy-5 g-xl-10 mb-5 mb-xl-0'>
        <div className='col-xl-8'>
          <DashboardDecks className='card-xl-stretch mb-xl-10' decks={dashboardData.decks} disabled={loading}/>
        </div>
        <div className='col-xl-4'>
          <DashboardAdds className='card-xl-stretch mb-xl-10' backGroundColor='#fcfcfb' />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <EnableSidebar>
      <EnableHeader>
        <DashboardDataProvider>
          <PageTitle breadcrumbs={[]}>
            {intl.formatMessage({id: 'GREETING'}) + ', ' + currentUser?.name}
          </PageTitle>
          <DashboardPage />
        </DashboardDataProvider>
      </EnableHeader>
    </EnableSidebar>
  )
}

export {DashboardWrapper}
