import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

declare global {
  interface Window {
    google: any;
  }
}


type Props = {
  handleCredentialResponse: (response: any) => void;
}

const GoogleOneTapLogin: React.FC<Props> = ({ handleCredentialResponse }) => {


  const client_id: string = "1061787652199-spf84ak2f217d51vlacv1trd8g8sh55p.apps.googleusercontent.com";
  const buttonDiv = useRef<HTMLDivElement>(null)
  const realButton = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(true)


  const initializeGoogleOneTap = useCallback(() => {
    if (!buttonDiv.current || !realButton.current) {
      return;
    }
  
    window.google.accounts.id.initialize({
      client_id: client_id,
      callback: handleCredentialResponse,
    });
  
    window.google.accounts.id.renderButton(buttonDiv.current, { 
      type: 'standard', 
      theme: 'outline', 
      size: 'large', 
      logo_alignment : 'center',
      width: realButton.current.offsetWidth + "px",
    });
  
    window.google.accounts.id.prompt();
    setLoading(false);
  }, [handleCredentialResponse, buttonDiv, realButton]);
  

  const loadGoogleOneTap = useCallback(() => {

    const script = document.createElement('script');
    script.id = "codigo-login-google"
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = false;
    script.defer = true;
    script.onload = initializeGoogleOneTap;
    document.body.appendChild(script);
   
  }, [initializeGoogleOneTap]);

 useEffect(() => {
  document.body.querySelectorAll("#codigo-signin-google").forEach((element)=>{
    element.remove()
  })  
  if (buttonDiv.current && realButton.current && document.body.querySelectorAll("#codigo-login-google").length === 0) {
    loadGoogleOneTap();
  }
}, [loadGoogleOneTap]);


  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={client_id}
        data-context="signup"
        data-ux_mode="redirect"
        data-auto_prompt="false"
      ></div>
      <div
        className="mt-1" style={{ width: realButton.current ? realButton.current.offsetWidth + "px": 'fit-content', opacity: 0, position: 'absolute'}}
        ref={buttonDiv}
      ></div>
      <div
        ref={realButton}
        className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
        {!loading && <>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </>
        }
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}

      </div>
    </>
  );
}

export default GoogleOneTapLogin;
