// import {KTSVG} from '../../../../../../../_metronic/helpers'
// import {useListView} from '../../core/ListViewProvider'
import {DecksListFilter} from './DecksListFilter'

const DecksListToolbar = () => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddDeckModal = () => {
  //   setItemIdForUpdate(null)
  // }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <DecksListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddDeckModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Deck
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {DecksListToolbar}
