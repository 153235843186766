/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../../../_metronic/helpers'

type Props = {
    level: number
}


const levelMapping: any = {
    // 0: {
    //     width: "0%",
    //     background: "bg-warning",
    //     text: 'LEARN.LEVEL.FIRST'
    // },
    0: {
        width: "20%",
        background: "bg-warning",
        text: 'LEARN.LEVEL.FIRST'
    },
    1: {
        width: "40%",
        background: "bg-warning",
        text: 'LEARN.LEVEL.SECOND'
    },
    2: {
        width: "60%",
        background: "bg-info",
        text: 'LEARN.LEVEL.THIRD'
    },
    3: {
        width: "80%",
        background: "bg-primary",
        text: 'LEARN.LEVEL.FOURTH'
    },
    4: {
        width: "100%",
        background: "bg-success",
        text: 'LEARN.LEVEL.FIFTH'
    },
}

const LevelProgress: FC<Props> = ({
    level,
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    let values = levelMapping[(level ?? 0) < 4 ? (level ?? 0) : 4]
    let progress: any = [
    ]
    for (var i = 0; i < 5; i++) {
        if (i <= level) {
            progress.push(<div key={i} className={`flex-grow-1 ${values.background} rounded h-5px me-2`}></div>)
        }
        else {
            progress.push(<div key={i} className={'flex-grow-1 bg-secondary rounded h-5px me-2'}></div>)
        }
    }
    const intl = useIntl()

    return (
        <button
            type='button'
            className='rounded btn btn-sm btn-icon btn-color-secondary btn-active-light-secondary d-flex align-items-center h-100 w-100' style={{ maxWidth: 'fit-content' }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_levels_info'
        >
            <div className='d-flex flex-grow-1 p-3 mb-2 my-auto'>
                <div className='progress h-6px w-100 my-auto' style={{ minWidth: "100px", maxWidth: "200px", backgroundColor: "transparent" }}  aria-label='Current progress'>
                    {progress}
                </div>
            </div>
            <div className='d-flex p-3 w-100 my-auto' style={{ opacity: isHovering ? "1" : "0" }}>
                <span className='text-muted fs-6 fw-semibold my-auto'>{intl.formatMessage({ id: values.text })}</span>
                <KTSVG path='/media/icons/duotune/general/gen045.svg' className="svg-icon-2 text-gray-500 my-auto mx-1" />
            </div>
        </button>
    )
}

export { LevelProgress }
