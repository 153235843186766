import { FC } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'

type Props = {
    title?: string,
    subtitle?: string,
    description?: string
}

const HintWord: FC<Props> = ({
    title,
    subtitle,
    description
}) => {


    return (
        <>
            <div className='accordion accordion-icon-toggle2' id="kt_accordion_exercise">
                <div
                    className="accordion-header py-3 d-flex justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_exercise_item"
                >
                    <span className="fs-2 fw-bold">
                        {title}
                    </span>

                    <span className="accordion-icon">
                        <KTSVG
                            className="text-dark svg-icon svg-icon-1"
                            path="/media/icons/duotune/arrows/arr072.svg"
                        />
                    </span>

                </div>
                <div
                    id="kt_accordion_exercise_item"
                    className="collapse show"
                    data-bs-parent="#kt_accordion_exercise"
                >
                    {(subtitle) && <p className='fs-6 text-gray-600'>{subtitle}</p>}
                    <p className='fs-3 mt-3 text-gray-800'>{description}</p>
                </div>
            </div>
        </>
    )
}

export { HintWord }
