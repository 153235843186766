import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { DifficultyIcons } from './DifficultyIcons'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
  decks: any
  disabled: boolean
}

const DashboardDecks: React.FC<Props> = ({ className, decks = [] , disabled = false}) => {
  const intl = useIntl()
  const {currentUser} = useAuth();
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column w-50'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: "DASHBOARD.DECKS.TITLE" })}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <a
            href='/dashboard'
            className={`btn btn-sm btn-light-primary ${disabled && 'disabled'}`}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_decks'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({id: "DECKS.TABLE.TITLE"})}
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>{intl.formatMessage({ id: "DASHBOARD.DECKS.DECK" })}</th>
                <th className='min-w-120px'>{intl.formatMessage({ id: "DASHBOARD.DECKS.INFO" })}</th>
                <th className='min-w-150px'>{intl.formatMessage({ id: "DASHBOARD.DECKS.PROGRESS" })}</th>
                {/* <th className='min-w-50px text-center'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {decks.map((deck: any) => (
                <tr key={deck.deck_id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px overflow-hidden me-3'>
                          {deck.image_url ? (
                            <div className='symbol-label'>
                              <img src={toAbsoluteUrl(`${deck.image_url}`)} alt={deck.name} className='w-100' />
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                'symbol-label fs-3',
                                `bg-light-${deck.initials?.state}`,
                                `text-${deck.initials?.state}`
                              )}
                            >
                              {deck.initials?.label}
                            </div>
                          )}
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='/dashboard' className='text-dark fw-bold text-hover-primary fs-6'>
                          {deck.deck_name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {deck.size} words
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='/dashboard' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {deck.owner_id === currentUser?.id ? "Yo" : deck.owner_name ? deck.owner_name : 'Algernoun'}
                    </a>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      <DifficultyIcons level={deck.difficulty_level} />
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{Math.floor(deck.size > 0 ? deck.learned / deck.size * 100 : 0)}%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{ width: `${Math.floor(deck.size > 0 ? deck.learned / deck.size * 100 : 0)}%` }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  {/* <td>
                    <div className='d-flex justify-content-center flex-shrink-0'>
                      <a
                        href='/dashboard'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen019.svg'
                          className='svg-icon-3'
                        />
                      </a>
                      <a
                        href='/dashboard'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>
                      <a
                        href='/dashboard'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { DashboardDecks }