// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    className: string
    backGroundColor: string
}

const AboutUsIntro: FC<Props> = (
    {
        className,
        backGroundColor,
    }) => {

    return (
        <>
            <div className="mb-18">
                <div className="mb-10">
                    <div className="text-center mb-15">
                        <h3 className="fs-2hx text-dark mb-5">About Us</h3>
                        <div className="fs-5 text-muted fw-semibold">
                        Revolutionizing language learning and cultural exchange through our state-of-the-art app.
                        </div>
                    </div>
                    <div className="overlay">
                        <img className="w-100 card-rounded" src="/media/stock/1600x800/img-1.jpg" alt="" />
                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            {/* <a href="/pricing" className="btn btn-primary">Pricing</a>
                            <a href="/join-us" className="btn btn-light-primary ms-3">Join Us</a> */}
                        </div>
                    </div>
                </div>
                <div className="fs-5 fw-semibold text-gray-600">
                    <p className="mb-8">
                        Our app uses cutting-edge spaced memory techniques, designed to streamline your language learning process. We currently support 10 languages, all pairing with each other and offering comprehensive tools for word and sentence context understanding. With a database of more than 100 million sentences, our system creates topic decks to personalize your learning journey.
                    </p>
                    <p className="mb-8">
                        Our mission is to provide support to marginal languages with minor resources. Leveraging advanced LLM and machine learning techniques, we aim to reduce content costs, making language learning free and accessible to all, and nurturing a global community.
                    </p>
                    <p className="mb-8">
                        Although languages are our primary focus, we are in the process of extending our system to cover a diverse range of subjects. From song lyrics to Harry Potter spells, we plan to enable users to "deck" anything they're passionate about.
                    </p>
                    <p className="mb-17">
                        As we move forward, we will continue to innovate and provide high-quality resources to our users, paving the way for global connectivity and understanding.
                    </p>
                </div>
            </div>
            <div className="card bg-light mb-18">
                <div className="card-body py-15">
                <div className="d-flex flex-center">
                        <div className="d-flex flex-center flex-wrap mb-10 mx-auto gap-5 w-xl-900px">
                            <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">
                                <div className="text-center">
                                    <span className="svg-icon svg-icon-2tx svg-icon-primary">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x={2} y={2} width={9} height={9} rx={2} fill="currentColor" />
                                            <rect opacity="0.3" x={13} y={2} width={9} height={9} rx={2} fill="currentColor" />
                                            <rect opacity="0.3" x={13} y={13} width={9} height={9} rx={2} fill="currentColor" />
                                            <rect opacity="0.3" x={2} y={13} width={9} height={9} rx={2} fill="currentColor" />
                                        </svg>
                                    </span>
                                    <div className="mt-1">
                                        <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                            <div className="min-w-70px">100</div>+</div>
                                        <span className="text-gray-600 fw-semibold fs-5 lh-0">Decks</span>
                                    </div>
                                </div>
                            </div>
                            <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">
                                <div className="text-center">
                                    <span className="svg-icon svg-icon-2tx svg-icon-success">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z" fill="currentColor" />
                                            <path opacity="0.3" d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z" fill="currentColor" />
                                            <path opacity="0.3" d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                    <div className="mt-1">
                                        <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                            <div className="min-w-50px">10</div>M+</div>
                                        <span className="text-gray-600 fw-semibold fs-5 lh-0">Examples</span>
                                    </div>
                                </div>
                            </div>
                            <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-lg-10">
                                <div className="text-center">
                                    <span className="svg-icon svg-icon-2tx svg-icon-info">
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z" fill="currentColor" />
                                            <path opacity="0.3" d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z" fill="currentColor" />
                                            <path opacity="0.3" d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                    <div className="mt-1">
                                        <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                            <div className="min-w-50px">0</div>$</div>
                                        <span className="text-gray-600 fw-semibold fs-5 lh-0">Cost</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fs-2 fw-semibold text-muted text-center mb-3">
                        <span className="fs-1 lh-1 text-gray-700">“</span>
                        Language is the road map of a culture. It tells you where its people come from and where they are going.
                        <span className="fs-1 lh-1 text-gray-700">“</span>
                    </div>
                    <div className="fs-2 fw-semibold text-muted text-center">
                        <a target='_blank' rel="noreferrer" href="https://es.wikipedia.org/wiki/Rita_Mae_Brown" className="link-primary fs-4 fw-bold">Rita Mae Brown</a>
                        {/* <span className="fs-4 fw-bold text-gray-600">,Your Position</span> */}
                    </div>
                </div>
            </div>
        </>

    );
}

export { AboutUsIntro }
