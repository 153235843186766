import axios, {AxiosResponse} from 'axios'
import { DashboardDatResponse } from './_models';


const API_URL = process.env.REACT_APP_THEME_API_URL
const DASHBOARD_URL = `${API_URL}/dashboard`

const getDashboardData = (): Promise<DashboardDatResponse> => {

  return axios
    .get(`${DASHBOARD_URL}/dashboard_data`)
    .then((response: AxiosResponse<DashboardDatResponse>) => response.data)
    // .then((response: DashboardDatResponse) => response.data)
}

// const addFavoriteWord = (input: AddFavoriteInput): Promise<Boolean> => {

//   const data = qs.stringify(input);

//   return axios
//     .post(`${LEARN_URL}/favorite`, data)
//     .then((response: AxiosResponse<any>) => response.data)
//     .then((response: any) => response.data)
// }

// const setUserProgress = (input: setUserProgressInput): Promise<Boolean> => {

//   const data = qs.stringify(input);

//   return axios
//     .post(`${LEARN_URL}/progress`, data)
//     .then((response: AxiosResponse<any>) => response.data)
//     .then((response: any) => response.data)
// }

export {getDashboardData}

