// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    className: string
    backGroundColor: string
}

const AboutUsNetwork: FC<Props> = (
    {
        className,
        backGroundColor,
    }) => {

    return (
        <>
            <div className="card mb-4 bg-light text-center">
                <div className="card-body py-12">
                    <a href="https://www.facebook.com/profile.php?id=100093194666228"  target='_blank' rel="noreferrer" className="mx-4">
                        <img src="/media/svg/brand-logos/facebook-4.svg" className="h-30px my-2" alt="" />
                    </a>
                    <a href="https://www.instagram.com/algernoun/" target='_blank' rel="noreferrer" className="mx-4">
                        <img src="/media/svg/brand-logos/instagram-2-1.svg" className="h-30px my-2" alt="" />
                    </a>
                    {/* <a href="/" className="mx-4">
                        <img src="/media/svg/brand-logos/github.svg" className="h-30px my-2" alt="" />
                    </a> */}
                    <a href="https://twitter.com/algernoun" target='_blank' rel="noreferrer" className="mx-4">
                        <img src="/media/svg/brand-logos/twitter.svg" className="h-30px my-2" alt="" />
                    </a>
                </div>
            </div>
        </>
    );
}

export { AboutUsNetwork }
