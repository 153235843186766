// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    className: string
    backGroundColor: string
}

const AboutUsPublications: FC<Props> = (
    {
        className,
        backGroundColor,
    }) => {

    return (
        <>
            <div className="mb-16">
                <div className="text-center mb-12">
                    <h3 className="fs-2hx text-dark mb-5">Inspiration</h3>
                    <div className="fs-5 text-muted fw-semibold">Our dedication is to revolutionize language learning and cultural exchange.
                        <br />Here are some inspirational resources that drive our vision</div>
                </div>
                <div className="row g-10">
                    <div className="col-md-4">
                        <div className="card-xl-stretch me-md-6">
                            <a className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales" target='_blank' rel="noreferrer" href="https://translatorswithoutborders.org/blog/marginalized-mother-languages-two-ways-to-improve-the-lives-of-the-people-who-speak-them/">
                                <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: 'url("/media/stock/about/TWB.webp")' }} />
                                <div className="overlay-layer bg-dark card-rounded bg-opacity-25">
                                    <i className="bi bi-eye-fill fs-2x text-white" />
                                </div>
                            </a>
                            <div className="m-0">
                                <a target='_blank' rel="noreferrer" href="https://translatorswithoutborders.org/blog/marginalized-mother-languages-two-ways-to-improve-the-lives-of-the-people-who-speak-them/" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">Marginalized Mother Languages - Two Ways to Improve Lives</a>
                                <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5">An insightful article highlighting the importance of marginalized languages and ways to support them.</div>
                                <div className="fs-6 fw-bold">
                                    <a target='_blank' rel="noreferrer" href="https://translatorswithoutborders.org/" className="text-gray-700 text-hover-primary">Translators without Borders</a>
                                    <span className="text-muted"> on Feb 21 2019</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card-xl-stretch me-md-6">
                            <a className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales" target='_blank' rel="noreferrer" href="https://mc-lawgroup.com/how-do-language-and-cultural-barriers-make-it-difficult-for-immigrants/">
                                <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: 'url("/media/stock/about/How-Do-Language-and-Cultural-Barriers-Make-It-Difficult-for-Immigrants.jpeg")' }} />
                                <div className="overlay-layer bg-dark card-rounded bg-opacity-25">
                                    <i className="bi bi-eye-fill fs-2x text-white" />
                                </div>
                            </a>
                            <div className="m-0">
                                <a target='_blank' rel="noreferrer" href="https://mc-lawgroup.com/how-do-language-and-cultural-barriers-make-it-difficult-for-immigrants/" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">How Do Language and Cultural Barriers Make It Difficult For Immigrants?</a>
                                <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5">An exploration of the language and cultural challenges faced by immigrants, and how these barriers impact their lives and rights.</div>
                                <div className="fs-6 fw-bold">
                                    <a target='_blank' rel="noreferrer" href="https://mc-lawgroup.com/" className="text-gray-700 text-hover-primary">MC Law Group</a>
                                    <span className="text-muted"> on February 17, 2022</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card-xl-stretch me-md-6">
                            <a className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales" target='_blank' rel="noreferrer" href="https://www.researchgate.net/publication/220724346_About_the_Benefits_of_Exploiting_Natural_Language_Processing_Techniques_for_E-Learning">
                                <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: 'url("/media/stock/about/NLP.webp")' }} />
                                <div className="overlay-layer bg-dark card-rounded bg-opacity-25">
                                    <i className="bi bi-eye-fill fs-2x text-white" />
                                </div>
                            </a>
                            <div className="m-0">
                                <a target='_blank' rel="noreferrer" href="https://www.researchgate.net/publication/220724346_About_the_Benefits_of_Exploiting_Natural_Language_Processing_Techniques_for_E-Learning" className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base">About the Benefits of Exploiting NLP Techniques for E-Learning</a>
                                <div className="fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5">An exploration of the benefits of utilizing natural language processing techniques in e-learning systems, enhancing their functionality and adaptability.</div>
                                <div className="fs-6 fw-bold">
                                    <a target='_blank' rel="noreferrer" href="https://mc-lawgroup.com/" className="text-gray-700 text-hover-primary">Researchgate</a>
                                    <span className="text-muted"> on January, 2008</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    );
}

export { AboutUsPublications }
