import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'

type Props = {
    word: string;
    correctAnswer: boolean;
    inputValue: string;
    placeholder: string;
    recording: boolean;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checkAnswer: () => void;
    disabled: boolean;

}

const InputFillWord: FC<Props> = ({
    word,
    correctAnswer,
    inputValue,
    placeholder,
    recording,
    handleInputChange,
    checkAnswer,
    disabled
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const myRef = useRef<HTMLParagraphElement>(document.createElement("p"));
    const [width, setWidth] = useState<Number>();

    useEffect(() => {
        setWidth(myRef.current.clientWidth)
        inputRef?.current?.focus();
    }, [word]);

    useEffect(() => {
        if (inputValue.toLowerCase() === word.toLowerCase() && recording){
            checkAnswer()
        }
    }, [inputValue, word, checkAnswer, recording]);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            checkAnswer();
        }
      };
    return (
        <span className={`d-inline-block ${correctAnswer ? 'bg-light-success':'bg-secondary'} px-lg-2 px-xl-2 px-sm-0 px-md-0 px-0 rounded-1`}>
            <input className={`${correctAnswer ? 'text-success':'text-primary'} bg-transparent border-0`} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" data-word="day" data-lpignore="true"
                ref={inputRef}
                aria-label='Fill the gap'
                value={correctAnswer ? word: inputValue}
                placeholder={placeholder}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                // onBlur={checkAnswer}
                disabled = {disabled}
                style={{
                    outline: "none",
                    boxSizing: "content-box",
                    maxWidth: "400px",
                    minWidth: `calc(${width}px + 2px)`,
                    width: "2px",
                  }} />
            <p ref={myRef} style={{ position: "absolute", width: "fit-content", opacity: "0" }}>
                {word}
            </p>
            <span className="hidden-word-container d-none">
                <span className="is-letter">d</span>
                <span className="is-letter">a</span>
                <span className="is-letter">y</span>
            </span>
        </span>
    )
}

export { InputFillWord }
